export default {
  2019: [
    {
      name: 'Interna 2019 "Łatwe pytania - trudne odpowiedzi"',
      url: '/interna-2019 '
    },
    {
      name: 'PEDIATRIA 2019 "Kiedy kierować do specjalisty?"',
      url: 'pediatria2019'
    },
    {
      name: 'NEUROLOGIA 2019 "Logowanie do nowej wiedzy"',
      url: '/neurologia-2019'
    },
    {
      name: 'PSYCHIATRIA "Soma i psyche - lustrzane odbicie chorób"',
      url: '/psychiatria-2019'
    },
    {
      name: 'KARDIOLOGIA 2019 "W sercu kardiologii"',
      url: '/kariologia-2019'
    },
  ],
  2018: [
    {
      name: 'GERIATRIA 2018 "Gdy standardy to za mało"',
      url: '/geriatria-2018'
    },
    {
      name: 'Ciężarna z chorobą internistyczną 2018 "ODMIENNOŚCI POSTĘPOWANIA U CIĘŻARNEJ"',
      url: '/ciezarna-2018'
    },
    {
      name: 'ALGORYTMY W GINEKOLOGII I POŁOŻNICTWIE 2018 STANY NAGŁE "Presja czasu – siła wiedzy"',
      url: '/ginekologia-2018'
    },
    {
      name: 'STANY NAGŁE "Presja czasu - siła wiedzy"',
      url: '/ginekologia-2018'
    },
    {
      name: 'PEDIATRIA (Katowice) „Wyzwania – moja specjalność”',
      url: '/pediatriakatowice2018'
    },
    {
      name: 'INTERNA „Od algorytmu do indywidualnej historii”',
      url: 'interna2018'
    },
    {
      name: 'PEDIATRIA (Warszawa) „Wyzwania – moja specjalność”',
      url: 'pediatria2018'
    },
  ],

  2017: [
    {
      name: 'Ciężarna z chorobą internistyczną',
      url: '/ciezarna2017'
    },
    {
      name: 'Geriatria',
      url: '/geriatria2017'
    },
    {
      name: 'Stany nagłe',
      url: '/stanynagle2017'
    },
    {
      name: 'Algorytmy w ginekologii i położnictwie',
      url: '/ginekologia2017'
    },
    {
      name: 'INTERNA "Algorytmy w internie"',
      url: '/interna2017'
    },
    {
      name: 'Stomatologia',
      url: '/stomatologia2017'
    },
    {
      name: 'PEDIATRIA "Algorytmy w pediatrii"',
      url: '/pediatria2017'
    },
  ],

  2016: [
    {
      name: 'Geriatria',
      url: '/apd_geriatria_2016'
    },
    {
      name: 'Ciężarna z chorobą internistyczną',
      url: '/apd_ciezarna_z_choroba_int_2016'
    },
    {
      name: 'KURS EKG Prosto o Krzywych',
      url: '/apd_kurs_ekg_2016'
    },
    {
      name: 'Ból',
      url: '/apd_bol_2016'
    },
    {
      name: 'Dermatologia w POZ',
      url: '/apd_dermatologia_2016'
    },
    {
      name: 'Stany nagłe',
      url: '/apd_stany_nagle_2016'
    },
    {
      name: 'Algorytmy postępowania w ginekologii i położnictwie',
      url: '/apd_algorytmy_ginekologia_2016'
    },
    {
      name: 'Multiforum Interna 2016',
      url: '/multiforum_interna2016'
    },
    {
      name: 'Pediatria',
      url: '/apd_pediatria'
    },
    {
      name: 'Stomatologia',
      url: '/apd_stomatologia'
    },
  ],

  2015: [
    {
      name: 'Ciężarna z chorobą internistyczną',
      url: '/apd_ciezarna_z_choroba_int'
    },
    {
      name: 'Ból',
      url: '/apd_bol'
    },
    {
      name: 'Dermatologia',
      url: '/apd_dermatologia_2015'
    },
    {
      name: 'Prawo kontra ginekolog-położnik',
      url: '/apd_prawo_kontra_ginekolog_poloznik_2015'
    },
    {
      name: 'Okulistyka w POZ',
      url: '/apd_okulistyka_w_poz_2015'
    },
    {
      name: 'Stany nagłe',
      url: '/apd_stany_nagle_2015'
    },
    {
      name: 'Psychiatria',
      url: '/apd_psychiatria_2015'
    },
  ],

}

