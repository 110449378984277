import {Model} from '@itsfadnis/jsonapi-client';

export class ConferenceSectionSummaries extends Model {
  static _type = 'conference_section_summaries';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.block = args.block;
  }
}