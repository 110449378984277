import React, {Component} from 'react';
import {Sort} from '../../helpers/sort';
import {Filter} from '../../helpers/filter';
import Popup from './popups/popup';
import {PlayIcon} from '../../assets/icons/icons';

export default class PreviousCongresses extends Component {

  _createObjectWithPositions = (data) => {
    const objectOfPosition = {}
    for (let i=1; i<=data.length; i++) {
      objectOfPosition[i] = Filter.byPosition(data, i);
    }
    return objectOfPosition
  };

  render() {
    const {data} = this.props;
    const previousEdition = data.previousEditions[0];

    const componentsSortedByPosition = Sort.by_position(previousEdition.components);
    const objectOfPosition = this._createObjectWithPositions(componentsSortedByPosition);

    return (
        <div>
          {data.previousEditions.length > 0 &&
            <div className='page__section previous-congresses' id='previous_edition'>
              <h3 className='section-title'>
                Poprzednie edycje
              </h3>
              <div className='gallery-attached'>
                <div className="gallery-attached__row">
                  <div className="gallery-attached__col gallery-attached__col_5">
                    <DisplayPreviousContentByView data={objectOfPosition[1]}/>
                  </div>
                  <div className="gallery-attached__col gallery-attached__col_4">
                    <DisplayPreviousContentByView data={objectOfPosition[2]}/>
                  </div>
                  <div className="gallery-attached__col gallery-attached__col_3">
                    <DisplayPreviousContentByView data={objectOfPosition[3]}/>
                    <DisplayPreviousContentByView data={objectOfPosition[4]}/>
                  </div>
                </div>
                <div className="gallery-attached__row">
                  <div className="gallery-attached__col gallery-attached__col_4">
                    <DisplayPreviousContentByView data={objectOfPosition[5]}/>
                  </div>
                  <div className="gallery-attached__col gallery-attached__col_3">
                    <DisplayPreviousContentByView data={objectOfPosition[6]}/>
                    <DisplayPreviousContentByView data={objectOfPosition[7]}/>
                  </div>
                  <div className="gallery-attached__col gallery-attached__col_5">
                    <DisplayPreviousContentByView data={objectOfPosition[8]}/>
                  </div>
                </div>
              </div>
              <p className='previous-congresses__annotation'>
                {previousEdition.annotation}
              </p>
            </div>
          }
        </div>
    )
  }
}

function DisplayPreviousContentByView(props) {
  const {data} = props;
  const view = data.view

  switch(view) {
    case 'image':
      if (data.position === 1 || data.position === 8) {
        return <PreviousCongressImageComponent size='big' data={data}/>
      } else {
        return <PreviousCongressImageComponent size='small' data={data}/>
      }

    case 'video':
      return <PreviousCongressVideoComponent data={data}/>

    default:
      return <PreviousCongressTextComponent data={data}/>
  }
}

function PreviousCongressTextComponent(props){
  const {data} = props;
  const sortedTextsByPosition = Sort.by_position(data.texts)

  return (
      <div className="gallery-text-block">
        <p className='gallery-text-block__title'>
          {data.title}
        </p>
        <div className="gallery-text-block__row">
          <div className="gallery-text-block__info">
            <p className='gallery-text-block__number'>
              {sortedTextsByPosition[0].value}
            </p>
            <p className='gallery-text-block__description'>
              {sortedTextsByPosition[1].value}
            </p>
          </div>
          <div className="gallery-text-block__info">
            <p className='gallery-text-block__number'>
              {sortedTextsByPosition[2].value}
            </p>
            <p className='gallery-text-block__description'>
              {sortedTextsByPosition[3].value}
            </p>
          </div>
        </div>
      </div>
  )
}

function PreviousCongressImageComponent(props){
  const {data, size} = props;

  switch(size) {
    case 'small':
      return (
          <img src={data.mediumImage} alt='Zdjęcie z poprzedniej edycji' className='gallery-attached__image gallery-attached__image_sm'/>
      )
    default:
      return (
          <img src={data.mediumImage} alt='Zdjęcie z poprzedniej edycji' className='gallery-attached__image' />
      )
  }
}

class PreviousCongressVideoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false
    }
  }

  _togglePopup = () => {
    this.setState({
      popupOpen: !this.state.popupOpen
    })
  }

  render() {
    const {data} = this.props;

    return (
      <div className='gallery-attached__image-container' onClick={this._togglePopup}>
        <img src={data.mediumImage} className='gallery-attached__video' alt='Film z poprzedniej edycji'/>
        <PlayIcon width='40px' height='40px' className='icon_white icon_absolute'/>
        {this.state.popupOpen &&
          <Popup data={data} type='previousEdition' popupShow={this.state.popupOpen} closePopup={this._togglePopup}/>
        }
      </div>
    )
  }
}