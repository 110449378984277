import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class FormSendButton extends Component {
  static propTypes = {
    messageSent: PropTypes.bool.isRequired,
    formValid: PropTypes.bool.isRequired
  };

  render() {
    const {messageSent, formValid} = this.props;
    const isActive = messageSent ? false : formValid;
    const text = messageSent ? 'Wiadomość została wysłana' : 'Wyślij'
    return (
        <button type='submit' className={`form__input input button button-success ${isActive ? '' : 'button-disabled'}`}>
          {text}
        </button>
    )
  }
}