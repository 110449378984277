import {Model} from '@itsfadnis/jsonapi-client';
import {ConferenceSectionPracticalInformationComponents} from './ConferenceSectionPracticalInformationComponents';

export class ConferenceSectionPracticalInformations extends Model {
  static _type = 'conference_section_practical_informations';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.components = this.hasMany(ConferenceSectionPracticalInformationComponents, args.components);
  }
}