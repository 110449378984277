import React, { Component } from 'react';
import ArchivedCongress from './archived_congress';

export default class ArchivedYear extends Component {
  _reduceRepeatingPastCongresses = (congressesInYear) => {
    const arrayOfNames = congressesInYear.map(congress => congress.hasOwnProperty('name') ? congress.name : congress.conferenceInfo.specialization);
    const uniqueNames = [...new Set(arrayOfNames)];
    return uniqueNames.map(name => congressesInYear.find(congress => (
      congress.hasOwnProperty('name')
        ? congress.name === name
        : congress.conferenceInfo.specialization === name
    )))
  };

  render() {
    const { year, congressesInYear } = this.props;
    const uniqueCongressesInYear = this._reduceRepeatingPastCongresses(congressesInYear);

    return (
      <div className='grid__col_12 grid__col_md_4'>
        {uniqueCongressesInYear.length > 0 &&
        <div className='congress-list-archive-card'>
          <h4 className='congress-list-archive-card__title'>
            {year}
          </h4>
          {uniqueCongressesInYear.map((congress, index) =>
            <ArchivedCongress data={congress} key={index}/>
          )}
        </div>
        }
      </div>
    )
  }

}