export default {
  subscribe : {
    links : [
      {
        path: '/s/pren-chpd',
        name: 'Chirurgia po Dyplomie',
      },
      {
        path: '/s/pren-derpd',
        name: 'Dermatologia po Dyplomie',
      },
      {
        path: '/s/pren-dpd',
        name: 'Diabetologia po Dyplomie',
      },
      {
        path: '/s/pren-gpd',
        name: 'Ginekologia po Dyplomie',
      },
      {
        path: '/s/pren-kpd',
        name: 'Kardiologia po Dyplomie',
      },
      {
        path: '/s/pren-mt',
        name: 'Medical Tribune',
      },
      {
        path: '/s/pren-mts',
        name: 'Medical Tribune Stomatologia',
      },
      {
        path: '/s/pren-mpd',
        name: 'Medycyna po Dyplomie',
      },
      {
        path: '/s/pren-npd',
        name: 'Neurologia po Dyplomie',
      },
      {
        path: '/s/pren-okpd',
        name: 'Okulistyka po Dyplomie',
      },
      {
        path: '/s/pren-opd',
        name: 'Onkologia po Dyplomie',
      },
      {
        path: '/s/pren-ppd',
        name: 'Pediatria po Dyplomie',
      },
      {
        path: '/s/pren-pspd',
        name: 'Psychiatria po Dyplomie',
      },
      {
        path: '/s/pren-snpd',
        name: 'Stany nagłe po Dyplomie',
      },
    ]
  },
}