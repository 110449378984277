import React, {Component} from 'react';
import Switcher from "./switcher";

export default class DetailsForCity extends Component {
  render() {
    const {conferencePlaces, currentCityId, changeCurrentCityId} = this.props;
    const text = conferencePlaces.length > 2 ? 'Wybierz miasto: ' : 'Szczegóły dla';

    return (
        <div className='details-for-city grid__padding-vertical'>
          <p className='details-for-city__text'>
            {text}
          </p>
          <Switcher main={true}
                    context='details-for-city'
                    conferencePlaces={conferencePlaces}
                    currentCityId={currentCityId}
                    changeCurrentCityId={changeCurrentCityId}/>

        </div>
    )
  }
}