import {Model} from '@itsfadnis/jsonapi-client';

export class ConferenceLogos extends Model {
  static _type = 'conferenceLogos';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.mediumImage = args.mediumImage;
    this.thumbImage = args.thumbImage;
    this.title = args.title;

    if (args.classification !== null) {
      this.classification = args.classification;
    }
    if (args.showInMenu !== null) {
      this.showInMenu = args.showInMenu;
    }
  }
}