import React from 'react';
import pediatriaNewPhoto from '../../assets/images/pediatria-2020-warszawa.png';

export default function HeaderBackgroundImage(props) {
  let { slug, city, sliders, className } = props;

  const setSource = () => {
    if (slug === 'pediatria-2020' && city === 'Warszawa') {
      return pediatriaNewPhoto
    } else {
      return sliders[0].mediumImage
    }
  };

  const src = setSource();

  return (
    <img src={src} alt="Obrazek główny kongresu" className={className}/>
  )
}