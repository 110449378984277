import {Model} from '@itsfadnis/jsonapi-client';

export class ConferenceSecionPreviousEditionComponentTexts extends Model {
  static _type = 'conference_section_previous_edition_component_texts';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.fontSize = args.fontSize;
    this.position = args.position;
    this.value = args.value;
  }
}