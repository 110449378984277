import React, {Component} from 'react';
import {ClockIcon, CoffeeIcon, MealIcon, PresentationIcon, ScheduleIcon, TalkingHeadsIcon} from "../../assets/icons/icons";

export default class BasicInfo extends Component {
  _isPositive = (string) => {
    return Number(string) > 0;
  };

  _checkAmountOfPositives = (arrayOfBasicInfos) => {
    return arrayOfBasicInfos.filter( i => this._isPositive(i)).length
  }

  render() {
    const {summary} = this.props;
    const isSmallView = window.isSmallView;
    const iconSize = isSmallView ? '40' : '70';

    const {lectures, hours, days, discussionBlocks, lunches, breaks} = summary.block;
    const amountOfBasicBlocks = this._checkAmountOfPositives([lectures, hours, days, discussionBlocks, lunches, breaks])
    const fixedWidthOfBlock = window.isMobileView ? (100/amountOfBasicBlocks)*2 + '%' : 100/amountOfBasicBlocks + '%';

    return (
        <div className='page__section basic-info' id='summary'>
          <div className="grid__container">
            <div className="grid__row">
              <div className="grid__col_12 grid__col_lg_3"/>
              <div className="grid__col_12 grid__col_lg_9">
                <ul className='grid__row basic-info__row'>
                  { this._isPositive(lectures) &&
                    <li className="basic-info__item basic-info-column" style={{width: fixedWidthOfBlock}}>
                      <PresentationIcon width={iconSize} height={iconSize} className='basic-info__icon basic-info-column__item'/>
                      <p className="basic-info-column__text basic-info-column__text_small basic-info-column__item">
                        wykłady:
                      </p>
                      <p className="basic-info-column__text basic-info-column__item">
                        {lectures}
                      </p>
                    </li>
                  }
                  { this._isPositive(hours) &&
                    <li className="basic-info__item basic-info-column" style={{width: fixedWidthOfBlock}}>
                      <ClockIcon width={iconSize} height={iconSize} className='basic-info__icon basic-info-column__item'/>
                      <p className="basic-info-column__text basic-info-column__text_small basic-info-column__item">
                        godziny:
                      </p>
                      <p className="basic-info-column__text basic-info-column__item">
                        {hours}
                      </p>
                    </li>
                  }
                  { this._isPositive(days) &&
                    <li className="basic-info__item basic-info-column" style={{width: fixedWidthOfBlock}}>
                      <ScheduleIcon width={iconSize} height={iconSize} className='basic-info__icon basic-info-column__item'/>
                      <p className="basic-info-column__text basic-info-column__text_small basic-info-column__item">
                        dni:
                      </p>
                      <p className="basic-info-column__text basic-info-column__item">
                        {days}
                      </p>
                    </li>
                  }

                  { this._isPositive(discussionBlocks) &&
                    <li className="basic-info__item basic-info-column" style={{width: fixedWidthOfBlock}}>
                      <TalkingHeadsIcon width={iconSize} height={iconSize} className='basic-info__icon basic-info-column__item'/>
                      <p className="basic-info-column__text basic-info-column__item basic-info-column__text_small">
                        sesje Q&A:
                      </p>
                      <p className="basic-info-column__text basic-info-column__item">
                        {discussionBlocks}
                      </p>
                    </li>
                  }
                  { this._isPositive(lunches) &&
                    <li className="basic-info__item basic-info-column" style={{width: fixedWidthOfBlock}}>
                      <MealIcon width={iconSize} height={iconSize} className='basic-info__icon basic-info-column__item'/>
                      <p className="basic-info-column__text basic-info-column__text_small basic-info-column__item">
                        przerwy obiadowe:
                      </p>
                      <p className="basic-info-column__text basic-info-column__item">
                        {lunches}
                      </p>
                    </li>
                  }

                  { this._isPositive(breaks) &&
                    <li className="basic-info__item basic-info-column" style={{width: fixedWidthOfBlock}}>
                      <CoffeeIcon width={iconSize} height={iconSize} className='basic-info__icon basic-info-column__item'/>
                      <p className="basic-info-column__text basic-info-column__text_small basic-info-column__item">
                        przerwy kawowe:
                      </p>
                      <p className="basic-info-column__text basic-info-column__item">
                        {breaks}
                      </p>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
    )
  }
}