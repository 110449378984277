import React, {Component} from 'react';
import PropTypes from 'prop-types'
import AccordionItem from './accordion_item';
import {Sort} from '../../helpers/sort';

export default class Accordion extends Component {
  static propTypes = {
    items: PropTypes.instanceOf(Object).isRequired,
    allowMultipleOpen: PropTypes.bool,
    arrowColor: PropTypes.string
  };

  constructor(props) {
    super(props);
    let accordionItemsOpenState = {};

    this.props.items.forEach((item, index) => {
      //set default value if property is not present
      accordionItemsOpenState[index] = item.isOpen ? item.isOpen : false;
    });

    this.state = {accordionItemsOpenState}
  }

  _handleClick = (index) => {
    const {
      state: {accordionItemsOpenState},
      props: {allowMultipleOpen}
    } = this;

    const isOpen = !!accordionItemsOpenState[index];

    if (!allowMultipleOpen) {
      let keys = Object.keys(accordionItemsOpenState);

      keys.forEach((item, idx) => {
        accordionItemsOpenState[idx] = false
      })
    }

    this.setState({
      accordionItemsOpenState: {
        ...accordionItemsOpenState,
        [index]: !isOpen
      }
    })
  };

  render() {
    const {
      props: {items, arrowColor, rules},
      state: {accordionItemsOpenState}
    } = this;

    const sortedItems = Sort.by_lft(items);


    return (
        <div className='accordion'>
          {sortedItems.map((item, index) => (
              <AccordionItem
                isOpen={!!accordionItemsOpenState[index]}
                item={item}
                type={item.classification}
                onClick={() => {
                  this._handleClick(index)
                }}
                rules={rules}
                key={index}
                arrowColor={arrowColor}
              />
          ))}
        </div>
    )
  }
}