import {Model} from '@itsfadnis/jsonapi-client';

export class FooterPodyplomieSubmenu extends Model {
  static _type = 'menus';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.title = args.title;
    this.url = args.url;
    this.classification = args.classification;
    this.position = args.position;
    this.showInMenu = args.showInMenu;
    this.inNewWindow = args.inNewWindow;
    this.lft = args.lft;
    this.rgt = args.rgt;
  }
}