import {Model} from '@itsfadnis/jsonapi-client';

export class ConferenceSectionPracticalInformationComponents extends Model {
  static _type = 'conference_section_practical_information_components';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.title = args.title;
    this.mediumImage = args.mediumImage;
    this.thumbImage = args.thumbImage;
    this.htmlContent = args.htmlContent;
    this.lft = args.lft;
    this.classification = args.classification;
    this.url = args.url;
    this.buttonTitle = args.buttonTitle;
  }
}