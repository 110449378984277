import React, {Component} from 'react';
import HeaderPodyplomie from '../shared/_header_podyplomie';
import FooterPodyplomie from '../shared/_footer_podyplomie';
import {CongressListModel} from '../../models/congress_list/entity/CongressListModel';
import CongressListCard from './congress_list_card';
import moment from 'moment';
import {Sort} from '../../helpers/sort';
import _ from 'lodash';
import {PdfColorIcon} from '../../assets/icons/icons';
import Api, {base_url} from '../../api/Api';
import SeoTags from '../shared/_seo_tags';
import ArchivedCongresses from './archive/archived_congresses';

export default class CongressList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      isLoading: true,
      isLoggedIn: false,
      productsInCart: 0,
    }
  }

  _futureCongresses = (arrayOfEvents) => {
    return arrayOfEvents.filter(event => event.isFuture)
  };

  _pastCongresses = (arrayOfEvents) => {
    return arrayOfEvents.filter(event => event.isPast)
  };

  _sortEventsByYears = (events) => {
    const date = item => moment(item.starting).year();
    const sortedEvents = Sort.by_startDate(events);
    return _.groupBy(sortedEvents, date)
  };

  _checkUserAttributes = () => {
    Api
        .getUserHeaderInfo()
        .then(response => response.json())
        .then(data =>
            this.setState({
              isLoggedIn: data.api_token !== null,
              productsInCart: data.products_in_cart
            })
        )
      .catch(error => {
        console.log(error)
        this.setState({ isLoggedIn: false })
      })
  };

  componentDidMount() {
    CongressListModel.fetch(`?include=conferenceInfo,conferenceInfo.sliders,sections,sections.invitations,sections.invitations.lecturers`)
        .then(data => {
          this.setState({response: data})
        }).then(() => {
      this.setState({
        isLoading: false
      })
    });

    this._checkUserAttributes()
  }

  render() {
    if (!this.state.isLoading) {

      const congresses = this.state.response;
      const futureCongresses = this._futureCongresses(congresses);
      const congressesByYears = this._sortEventsByYears(futureCongresses);

      const pastCongresses = this._pastCongresses(congresses);
      const pastCongressesByYears = this._sortEventsByYears(pastCongresses);

      const keysOfCongresses = Object.keys(congressesByYears);
      const rules = congresses[0].conferenceInfo.regulamin;
      const on_site_rules = congresses[0].conferenceInfo.onSiteTerms;

      const metaData = {
        title: 'Kongresy Akademii po Dyplomie',
        description: 'Odkryj nadchodzące Kongresy Akademii po Dyplomie',
        base_url: base_url + '/kongresy'
      };

      return (
          <div>
            <SeoTags metaData={metaData} type='list'/>
            <HeaderPodyplomie isLoggedIn={this.state.isLoggedIn} productsInCart={this.state.productsInCart}/>
            <section className='grid__container'>

              {keysOfCongresses.length > 0 && keysOfCongresses.map((year, index) =>
                  <div className='congress-list' key={index}>
                    <h3 className='congress-list__title'>Kalendarium kongresów Akademii po Dyplomie {year}</h3>
                    <div className='grid__row'>
                      {congressesByYears[year].length > 0 && congressesByYears[year].map((congress, index) => {
                            if (congress.publishedAt !== null) {
                              return (
                                  <div className='grid__col_12 grid__col_md_6 grid__col_lg_4' key={index}>
                                    <CongressListCard data={congress}/>
                                  </div>
                              )
                            }
                            return null
                          }
                      )}
                    </div>
                  </div>
              )}

              <div className='congress-list__rules'>
                <a href={rules} className='congress-list__rules-link' target='_blank' rel='noopener noreferrer'>
                  <PdfColorIcon width='40px' height='40px' className='icon_pdf-color'/>
                  Regulamin kongresów online Akademii po Dyplomie
                </a>
              </div>

            { on_site_rules && 
              <div className='congress-list__rules'>
                <a href={on_site_rules} className='congress-list__rules-link' target='_blank' rel='noopener noreferrer'>
                  <PdfColorIcon width='40px' height='40px' className='icon_pdf-color'/>
                  Regulamin kongresów stacjonarnych Akademii po Dyplomie
                </a>
              </div>
            }

              <ArchivedCongresses newCongressPages={pastCongressesByYears}/>

            </section>
            <FooterPodyplomie/>
          </div>
      )
    } else {
      return (
          <div className="bouncing-loader">
            <div/>
            <div/>
            <div/>
          </div>
      )
    }
  }
}

