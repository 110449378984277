import React, {Component} from 'react';
import Accordion from './accordion'

export default class CongressPracticalInfo extends Component {
  render() {
    const {practicalInfo, rules} = this.props;
    const practicalInformationComponents = practicalInfo.practicalInformations[0].components;

    return (
        <div className='page__section congress-info' id='practical_information'>
          <h3 className='section-title'>
            Praktyczne informacje
          </h3>
          <Accordion allowMultipleOpen arrowColor='green' items={practicalInformationComponents} isOpen={false} rules={rules}/>
        </div>
    )
  }
}