import moment from 'moment';
import {Verify} from './verify';

export const Parse = {
  timeOfDay: (date) => {
    return moment(date).format('HH:mm')
  },

  durationDates: (startDate, endDate, format) => {
    //startDate & endDate must be given as moment.js objects
    const start = moment(startDate),
          end   = moment(endDate);

    if(start.date() === end.date()) {
      return start.format(format)
    } else if (start.month() !== end.month()) {
      return `${start.format(format)} - ${end.format(format)}`
    } else {
      let firstDay = start.date();
      let lastDay = end.date();

      return `${firstDay}-${lastDay}.${start.format('MM.YYYY')}`
    }
  },

  timeLeft: (remainingTime) => {
    const getRemainingMonths = remainingTime.get('month')
    const getRemainingDays = remainingTime.get('date') -1;
    const getRemainingHours = remainingTime.get('hour');

    const parseMonths = () => {
      if (getRemainingMonths !== 0) {
        return `${getRemainingMonths} ${Verify.isNumberFromArray(getRemainingMonths, [1]) ? 'miesiąc' : Verify.isNumberFromArray(getRemainingMonths, [2,3,4]) ? 'miesiące' : 'miesięcy'},`
      } else {
        return ''
      }
    };

    const parseDays = () => {
      if (getRemainingDays !== 0 || (getRemainingDays === 0 && getRemainingMonths !== 0)) {
        return `${getRemainingDays} ${Verify.isNumberFromArray(getRemainingDays, [1]) ? 'dzień' : 'dni'},`
      } else {
        return ''
      }
    };

    const parseHours = () => {
     return `${getRemainingHours} ${Verify.isNumberFromArray(getRemainingHours, [2, 3, 4, 22, 23]) ? 'godziny' : Verify.isNumberFromArray(getRemainingHours, [1]) ? 'godzina' : 'godzin'}`
    };

    return `Zostało ${parseMonths()} ${parseDays()} ${parseHours()}`
  },

  placeAndDate : (place) => {
    return `${moment(place.starting).format('D.MM.YYYY')}, ${place.city}`
  }
};