import React, {Component} from 'react';

export default class PurchaseAmountWrapper extends Component {
  _increaseQuantity = () => {
    this.props.increaseQuantity();
  };

  _decreaseQuantity = () => {
    this.props.decreaseQuantity();
  };

  onQuantityChange = () => {
    this.props.onQuantityChange()
  };

  render() {
    const { quantity } = this.props;
    return (
      <div className='ticket-purchase-amount'>
        <button onClick={this._decreaseQuantity} className={`button ticket-purchase-amount__button ${quantity === 1 ? 'ticket-purchase-amount__button_disabled' : ''}`}>
          -
        </button>
        <input className='ticket-purchase-amount__number' min='0' max='10' type="number" onChange={this.onQuantityChange} value={quantity}/>
        <button onClick={this._increaseQuantity} className='button ticket-purchase-amount__button'>
          +
        </button>
      </div>
    )
  }
}