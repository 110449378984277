import { Model } from '@itsfadnis/jsonapi-client';
import * as PurchaseProcessor from '../../../helpers/purchase_processor';
import Api from '../../../api/Api';

export default class ConferenceTicketProduct extends Model {
  static _type = 'conference_ticket_products';

  constructor(args = {}) {
    super(args);

    this.id = args.id;
    this.productId = args.productId;
    this.campaignName = args.campaignName;
    this.campaignStartAt = args.campaignStartAt;
    this.campaignEndAt = args.campaignEndAt;
    this.campaignDisplayName = args.campaignDisplayName;
    this.campaignDisplayComment = args.campaignDisplayComment;
    this.name = args.name;
    this.serviceCode = args.serviceCode;
    this.productType = args.productType;
    this.basePrice = args.basePrice;
    this.businessPrice = args.businessPrice;
    this.baseDiscountPrice = args.baseDiscountPrice;
    this.businessDiscountPrice = args.businessDiscountPrice;
    this.backgroundImage = args.mediumPicture;

    this.sendToCart = (quantity) => {
      let url = PurchaseProcessor.buildAddToCartUrl(this.productId, quantity);
      return Api.sendProducts(url)
    }
  }
}