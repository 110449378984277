export const Verify = {
  isNumberFromArray: (dateToCheck ,[...numbers]) => {
    let testIfDateIsNumberFromArray = false;

    numbers.forEach((number) => {
      if(number === dateToCheck) {
        testIfDateIsNumberFromArray = true;
      }
    });

    return testIfDateIsNumberFromArray;
  }
}