import React, {Component} from 'react';
import {CardHorizontalNarrow} from "./cards_horizontal";

export default class LiveTransmission extends Component {
  render() {
    const {liveTransmission} = this.props

    const liveTransmissionData = liveTransmission.liveTransmissions[0]

    return (
        <div className='cards'>
          <div className='page__section' id='live_transmission'>
            <h4 className='section-title section-title_small'>
              {liveTransmissionData.sectionTitle}
            </h4>
            <CardHorizontalNarrow content={liveTransmissionData} framed={true} type='stream'/>
          </div>
        </div>
    )
  }
}