import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class MenuLinkItem extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    className: PropTypes.string
  }
  render() {
    const {url, content, className} = this.props
    return (
        <li className='menu__item'>
          <a href={url} className={`link link_menu ${className}`}>
            {content}
          </a>
        </li>
    )
  }
}