import React, {Component} from 'react';

export default class ErrorView extends Component {
  render() {
    return (
        <div className='error-handler'>
          <h1 className='error-handler__title'>Coś poszło nie tak</h1>
          <p className='error-handler__text'>
            Spróbuj uruchomić stronę w innej przeglądarce (np. Google Chrome, Mozilla Firefox lub Microsoft Edge).
          </p>
          <p className='error-handler__text'>
            Jeżeli to nie przynosi efektu, skontaktuj się z nami drogą mailową na adres <a href='mailto:kontakt@podyplomie.pl'>kontakt@podyplomie.pl</a>
          </p>
        </div>
    )
  }
}