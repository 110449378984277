import React, {Component} from 'react';

export class MenuBadgeAdjustment {
  constructor(badge_type) {
    this.badge_type = badge_type;
    this.content = this.adjustMenuBadge(this.badge_type);
  }

  adjustMenuBadge = (badge_type) => {
    switch(badge_type) {
      case 'newEdition':
        return 'Nowość!';
      case 'promotion':
        return 'Promocja';
      case 'recommend':
        return 'Polecamy';
      default:
        return undefined;
    }
  }
}

export default class MenuBadge extends Component {
  render() {
    const badge = new MenuBadgeAdjustment(this.props.badge_type);
    return (
        <span className={`menu-badge menu-badge_${badge.badge_type}`}>
          {badge.content}
        </span>
    )
  }
}