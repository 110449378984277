import React from 'react';
import { base_url } from '../../../api/Api';

export default function MainSummary (props) {

  const { product, date } = props;
  const productBackgroundImage = product.backgroundImage ? {backgroundImage: `url('${base_url}${product.backgroundImage}')`} : {};
  return (
      <div className='main-summary'>
        <p className='main-summary__title'>
          Produkt został dodany do koszyka
        </p>
        <article className="main-summary__product-info">
          <div className='main-summary__image' style={productBackgroundImage}/>
          <section className='main-summary__product-details'>
            <p className='main-summary__price'>
              {Number(product.baseDiscountPrice)} zł
            </p>
            <p className='main-summary__name'>
              {product.name}
            </p>
            <p className='main-summary__date'>
              {date}
            </p>
          </section>
        </article>
        <section className='main-summary__controls'>
          <button onClick={props.close} className='button-reset link link_bold link_blue'>
            Kontynuuj
          </button>
          <a href={`${base_url}/order`} className='button button-success'>
            Przejdź do koszyka
          </a>
        </section>
      </div>
  )

}