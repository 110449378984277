import React, {Component} from 'react';
import PropTypes from 'prop-types';
import bookingLogo from '../../assets/images/booking-logo.png'

export default class AccordionItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    arrowColor: PropTypes.string,
  };

  render() {
    const {
      props: {item, isOpen, arrowColor, onClick, type, rules},
    } = this;

    const arrColor = () => {
      switch (arrowColor) {
        case 'green':
          return 'green';
        default:
          return '';
      }
    };
    const setArrowColor = `arrow-sm_${arrColor()}`

    return (
      <RenderProperAccordionItemType
          item={item}
          isOpen={isOpen}
          arrowColor={setArrowColor}
          onClick={onClick}
          type={type}
          rules={rules}
      />
    )
  }
}

function RenderProperAccordionItemType(props) {
  const {item, isOpen, arrowColor, onClick, type, rules} = props;

  switch(type) {
    case 'regulamin':
      return <AccordionItemRules item={item} isOpen={isOpen} arrowColor={arrowColor} onClick={onClick} rules={rules}/>;
    case 'button':
      return <AccordionItemButton item={item} isOpen={isOpen} arrowColor={arrowColor} onClick={onClick}/>;
    case 'map':
      return <AccordionItemMap item={item} isOpen={isOpen} arrowColor={arrowColor} onClick={onClick}/>;
    default:
      return <AccordionItemBasic item={item} isOpen={isOpen} arrowColor={arrowColor} onClick={onClick}/>;
  }
}

function AccordionItemBasic(props) {
  const {item, isOpen, arrowColor, onClick} = props;

  return (
      <div className='accordion__item'>
        <div onClick={onClick} className='accordion__label'>
          {item.title}
          <span className={`arrow arrow-sm ${isOpen ? 'arrow-sm_up' : 'arrow-sm_down'} ${arrowColor}`}/>
        </div>
        <div className={`accordion__collapsible accordion__text ${isOpen ? '' : 'accordion__collapsible_hide'}`} dangerouslySetInnerHTML={{__html: item.htmlContent}}/>
      </div>
  )
}

function AccordionItemMap(props) {
  const {item, isOpen, arrowColor, onClick} = props;

  return (
      <div className='accordion__item'>
        <div onClick={onClick} className='accordion__label'>
          {item.title}
          <span className={`arrow arrow-sm ${isOpen ? 'arrow-sm_up' : 'arrow-sm_down'} ${arrowColor}`}/>
        </div>
        <div className={`accordion__collapsible ${isOpen ? '' : 'accordion__collapsible_hide'}`}>
          <p className='accordion__text' dangerouslySetInnerHTML={{__html: item.htmlContent}}/>
          <img src={item.mediumImage} alt='Mapa dojazdu' className='accordion__map'/>
        </div>
      </div>
  )
}

function AccordionItemRules(props) {
  const {item, isOpen, arrowColor, onClick, rules} = props;

  return (
      <div className='accordion__item'>
        <div onClick={onClick} className='accordion__label'>
          {item.title}
          <span className={`arrow arrow-sm ${isOpen ? 'arrow-sm_up' : 'arrow-sm_down'} ${arrowColor}`}/>
        </div>
        <div className={`accordion__collapsible ${isOpen ? '' : 'accordion__collapsible_hide'}`}>
          <p className='accordion__text' dangerouslySetInnerHTML={{__html: item.htmlContent}}/>
          <a href={rules} target='_blank' rel='noopener noreferrer' className='link link_black link_block'>
            Pobierz regulamin PDF
          </a>
        </div>
      </div>
  )
}

function AccordionItemButton(props) {
  const {item, isOpen, arrowColor, onClick} = props;
  const isLinkBooking = item.url.indexOf('booking') >= 0;

  return (
      <div className='accordion__item'>
        <div onClick={onClick} className='accordion__label'>
          {item.title}
          <span className={`arrow arrow-sm ${isOpen ? 'arrow-sm_up' : 'arrow-sm_down'} ${arrowColor}`}/>
        </div>
        <div className={`accordion__collapsible ${isOpen ? '' : 'accordion__collapsible_hide'} grid__row`}>
          <div className='grid__col_12'>
            <p dangerouslySetInnerHTML={{__html: item.htmlContent}} className='accordion__text'/>
          </div>
          <div className='grid__col_12 accordion_align-center'>
            <a href={item.url} className='link button button_md button-success' target='_blank' rel='noopener noreferrer'>
              {item.buttonTitle}
            </a>
            {isLinkBooking &&
              <img src={bookingLogo} alt="Logo booking.com" className='accordion__logo'/>
            }
          </div>
        </div>
      </div>
  )
}