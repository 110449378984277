import {Model} from '@itsfadnis/jsonapi-client';
import ConferenceTicketProduct from './ConferenceTicketProduct';
import moment from 'moment';
import { Parse } from '../../../helpers/parser';

export class ConferenceSectionTicketPool extends Model {
  static _type = 'conference_section_ticket_pools';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.allTicketsSold = args.allTicketsSold;

    this.ticketProduct = this.hasOne(ConferenceTicketProduct, args.ticketProduct);

    this.dateOfBeginning = moment(this.ticketProduct.campaignStartAt);
    this.dateOfExpiration = moment(this.ticketProduct.campaignEndAt);
    this.dateNow = moment();
    this.isActive = this.dateNow.isBetween(this.dateOfBeginning, this.dateOfExpiration);
    this.isFuture = this.dateNow < this.dateOfBeginning
    this.getRemaining = moment(this.dateOfExpiration - this.dateNow);
    this.parseTimeLeft = Parse.timeLeft(this.getRemaining)
    this.baseDiscountPrice = Number(this.ticketProduct.baseDiscountPrice);
    this.businessDiscountPrice = Number(this.ticketProduct.businessDiscountPrice);
    this.getExpFullDate = this.dateOfExpiration.format('DD.MM.YYYY');
    this.getStartDate = this.dateOfBeginning.format('D.MM');
  }
}