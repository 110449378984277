import {Model} from '@itsfadnis/jsonapi-client';

export class ConferenceSectionEntertainmentEvenings extends Model {
  static _type = 'conference_section_entertainment_evenings';

  constructor(args={}) {
    super(args);

    this.mediumImage = args.mediumImage;
    this.thumbImage = args.thumbImage;
    this.id = args.id;
    this.title = args.title;
    this.htmlContent = args.htmlContent;
  }
}