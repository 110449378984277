import React, {Component} from 'react';
import {Parse} from '../../helpers/parser';

export default class Switcher extends Component {
  constructor(props) {
    super(props);
    this.publishedConferencePlaces = this.props.conferencePlaces.filter(city => city.publishedAt !== null);
  }

  _changeCity = (e) => {
    if (e.target.options) {
      this.props.changeCurrentCityId(e.target.value)
    } else {
      this.props.changeCurrentCityId(e.target.dataset.id)
    }
  };

  render() {

    if (this.publishedConferencePlaces.length > 0) {
        return (
            <div className={`switcher ${this.props.context}__item`}>
              {this.publishedConferencePlaces.length > 2 &&
                  <div className='switcher__select-container'>
                    <select name="selectCity" id="selectCity" onChange={this._changeCity} className={`switcher__select switcher__select_${this.props.context}`} >
                      {this.publishedConferencePlaces.map((city, i) =>
                          <option value={city.id} key={i} selected={this.props.currentCityId === city.id}>
                            {Parse.placeAndDate(city)}
                          </option>
                      )}
                    </select>
                  </div>
              }

              {this.publishedConferencePlaces.length === 2 &&
                <div className='switcher__container'>
                  {this.publishedConferencePlaces.map((city, i) =>
                    <div
                        className={`switcher__item ${city.id === this.props.currentCityId ? 'switcher__item_active' : ''} ${this.props.main ? 'switcher__item_main' : ''}`}
                        onClick={this._changeCity}
                        key={i}
                        data-id={city.id}>
                      {city.city}
                    </div>
                  )}
                </div>
              }
            </div>
        )
    }
    return null
  }
}