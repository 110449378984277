import React, {Component} from 'react';

export default class HeaderBadge extends Component {
  render() {
    const {badgeData} = this.props;
    return (
        <div className='header__badge edu-points-badge'>
          <span className='edu-points-badge__number'>
            {badgeData.educationalPoints}
          </span>
          <span className='edu-points-badge__text'>
            punktów <br/> edukacyjnych
          </span>
        </div>
    )
  }
}