import React, {Component} from 'react';

export default class CongressSponsors extends Component {
  render() {
    const {data} = this.props;

    const logos = data.sponsors[0].logos;

    const ranks = [
        {
          type: 'Platynowy',
          logos: logos.filter(logosOf => {return logosOf.classification === 'platinum'})
        },
        {
          type: 'Złoty',
          logos: logos.filter(logosOf => {return logosOf.classification === 'gold'})
        },
        {
          type: 'Srebrny',
          logos: logos.filter(logosOf => {return logosOf.classification === 'silver'})
        },
        {
          type: 'Sponsor',
          logos: logos.filter(logosOf => {return logosOf.classification === 'other'})
        },
        {
          type: 'Wystawcy',
          logos: logos.filter(logosOf => {return logosOf.classification === 'exhibitor'})
        }

    ];

    return (
        <div>
          {logos.length > 0 &&
            <div className='congress-sponsors page__section' id='sponsor'>
              <h3 className='section-title'>
                Sponsorzy
              </h3>
              <div className='grid__container'>
                <div className='grid__row'>
                  {ranks.map((rank, index) =>
                    <div className='grid__col_12' key={index}>
                      {rank.logos.length > 0 &&
                        <div className='congress-sponsors__row'>
                          <p className='congress-sponsors__text'>
                            {rank.type}
                          </p>
                          <div className='congress-sponsors__logos'>
                            {rank.logos.map((logo, index) =>
                              <img src={logo.mediumImage}
                                alt='Sponsor kongresu'
                                key={index}
                                className={`congress-sponsors__logo ${logo.classification === 'platinum' ? 'congress-sponsors__logo_main' : ''}`}
                              />
                            )}
                            </div>
                        </div>
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>

          }
        </div>
    )
  }
}
