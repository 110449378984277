import {Model} from '@itsfadnis/jsonapi-client';
import {ConferenceSectionPreviousEditionComponents} from './ConferenceSectionPreviousEditionComponents';

export class ConferenceSectionPreviousEditions extends Model {
  static _type = 'conference_section_previous_editions';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.appearance = args.appearance;
    this.annotation = args.annotation;

    this.components = this.hasMany(ConferenceSectionPreviousEditionComponents, args.components)
  }

}