import {Model} from '@itsfadnis/jsonapi-client';
import {LecturerConnections} from './LecturerConnections';

export class ConferenceSectionLecturer extends Model {
  static _type = 'conference_section_lecturer';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.lecturerConnections = this.hasMany(LecturerConnections, args.lecturerConnections);
  }
}