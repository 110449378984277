import React, {Component} from 'react';
import SingleTicket from './single_ticket';
import Api from '../../api/Api';
import MarketingAlertTicket from './marketing_alerts/marketing_alert_ticket';
import HTMLContentHelper from '../../helpers/html_content_helper';
import Popup from './popups/popup';

export default class Tickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryOpen: false,
      popupProductId: null
    };
  }

  _openSummary = (id) => {
    this.setState({
      summaryOpen: true,
      popupProductId: id
    })
  };

  _closeSummary = () => {
    this.setState({summaryOpen: false})
  };

  componentDidMount() {
    Api
        .checkSoldFreeWorkshops(this.props.currentPlace.id)
        .then(response => response.json())
        .then(data => {this.setState({anyFreeTicketsLeft : this.props.currentPlace.freeTicketsToSell - data.free_workshops > 0})});
  }

  render() {
    const {ticket, currentPlace} = this.props;
    const background = ticket.tickets[0].backgroundImage.mediumImage;
    const isMobileView = window.isMobileView;
    const conferenceSectionTickets = ticket.tickets;
    const ticketsData = conferenceSectionTickets.length > 0 ? conferenceSectionTickets[0] : undefined;
    const { ticketPools } = ticketsData;
    const productId = this.state.popupProductId
    const popupData = {ticketsData, currentPlace, ticketPools, productId}

    return (
        <div>
          <div className='tickets-row page__section' id='ticket' style={{backgroundImage: !isMobileView ? 'url(' + background + ')' : 'none'}}>
            <div className="grid__container">
              <div className="grid__row">
                <div className="grid__col_12 grid__col_lg_3"/>
                <div className="grid__col_12 grid__col_lg_9">
                  <div className="tickets grid__padding-vertical" id='bilety'>
                    <div className="grid__row marketing-alert-row">
                      <div className='grid__col_12 grid__col_md_7'>
                        <p className="tickets__row section-title section-title_bold">
                          Bilety na kongres - {this.props.currentPlace.city}
                        </p>
                      </div>
                      <div className="grid__col_12 grid__col_md_5">
                        {HTMLContentHelper.hasHtmlStringInnerText(currentPlace.marketingAlertTicket) &&
                        <MarketingAlertTicket
                            data={currentPlace.marketingAlertTicket}
                            popupData={currentPlace.marketingAlertPopup}/>
                        }
                      </div>

                    </div>

                    <div className="grid__row tickets__row">
                      {ticketsData && ticketPools.map((pool, index) =>
                        <SingleTicket
                            pool={pool}
                            key={index}
                            index={index}
                            openSummary={this._openSummary}
                            currentPlace={this.props.currentPlace}
                            background={background}
                            amount={ticketPools.length}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.summaryOpen &&
          <Popup
            data={popupData}
            closePopup={this._closeSummary}
            type='purchaseSummary'
            popupShow={this.state.summaryOpen}
          />
          }

        </div>
    )
  }
}