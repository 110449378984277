import React, {Component} from 'react';
import {DiscussIcon, EmailPendingIcon} from '../../assets/icons/icons';
import ContactFormCongress from './forms/contact_form_congress';
import NewsletterFormCongress from './forms/newsletter_form_congress';
import contact_photo from '../../assets/images/contact_photo.png'

export default class ContactSection extends Component {
  render() {
    return (
        <div className='contact page__section' id='contact'>
          <h3 className='section-title'>
            Kontakt
          </h3>
          <div className='grid__row'>
            <div className='grid__col_12 grid__col_sm_6 grid__col_lg_6'>
              <p className='contact__question'>
                <span className='contact-question'>Masz pytania?</span>
                <DiscussIcon width='35' height='35' className='contact__icon'/>
              </p>
              <ContactFormCongress conferenceId={this.props.conferenceId}/>
            </div>
            <div className='grid__col_lg_1'/>
            <div className='grid__col_12 grid__col_sm_6 grid__col_lg_5 grid__negative-margin-right'>
              <div className='newsletter-form-container'>
                <div className='newsletter-form-container__header'>
                  <span className='newsletter-form__text'>Newsletter kongresowy</span>
                  <EmailPendingIcon width='40' height='40' className='contact__icon'/>
                </div>
                <NewsletterFormCongress conferenceId={this.props.conferenceId}/>
              </div>

              <div className='contact-data'>
                <div className='contact-data__row'>
                  <img src={contact_photo} alt='Zdjęcie osoby do kontaktu' className='contact-data__image'/>
                  <div className='contact-data__box'>
                    <p className='contact-data__title'>
                      Dla uczestnika
                    </p>
                    <p>
                      <a href="tel:801044415"  className='contact-data__link'>
                        801 044 415
                      </a>
                    </p>
                    <p>
                      <a href="tel:+48224442444" className='contact-data__link'>
                        +48 (22) 444 24 44
                      </a>
                    </p>
                    <p>
                      <a href="mailto:kontakt@podyplomie.pl" className='contact-data__link'>
                        kontakt@podyplomie.pl
                      </a>
                    </p>
                  </div>
                </div>
                <div className='contact-data__row'>
                  <div className='contact-data__box'>
                    <p className='contact-data__title'>
                      Współpraca
                    </p>
                    <p>
                      <a href="tel:801044415" className='contact-data__link'>
                        801 044 415
                      </a>
                    </p>
                    <p>
                      <a href="tel:+48224442400" className='contact-data__link'>
                        +48 (22) 444 24 00
                      </a>
                    </p>
                    <p>
                      <a href="mailto:reklama@medical-tribune.pl " className='contact-data__link'>
                        reklama@medical-tribune.pl
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  }
}