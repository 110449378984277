import React, { Component } from 'react';
import WorkshopList from './workshop_list';
import Entertainment from './entertainment';

export default class WorkshopsAndEntertainment extends Component {
  render() {
    const {
      workshopProducts,
      entertainmentProduct,
      allowMultipleWorkshops,
      entertainmentProductHeader,
    } = this.props;

    return (
        <div className="workshops-and-entertainment grid__row">
          {workshopProducts.length > 0 &&
            <div className={`grid__col_12 grid__col_md_${entertainmentProduct ? '8' : '12'}`}>
              <WorkshopList
                workshopProducts={workshopProducts}
                allowMultipleWorkshops={allowMultipleWorkshops}
              />
            </div>
          }

          {entertainmentProduct &&
            <div className={`grid__col_12 grid__col_md_${workshopProducts.length > 0 ? '4' : '12'}`}>
              <Entertainment
                entertainmentProduct={entertainmentProduct}
                header={entertainmentProductHeader}
              />
            </div>
          }
        </div>
    )
  }
}
