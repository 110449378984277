import moment from 'moment';
import _ from 'lodash';

export const Sort = {
  by_lft: (array) => {
    return array.sort((a, b) => {
      return a.lft - b.lft
    })
  },

  by_position: (array) => {
    return array.sort((a, b) => {
      return a.position - b.position
    })
  },

  nested_by_lastname: (array, attribute) => {
    return array.sort((a, b) => {
      if (a[attribute].lastName < b[attribute].lastName) {return -1}
      if (a[attribute].lastName > b[attribute].lastName) {return 1}
      return 0
    })
  },

  by_startDate: (array) => {
    return array.sort((a, b) => {
      let date1 = moment(a.starting);
      let date2 = moment(b.starting);
      return date1 > date2 ? 1 : date1 < date2 ? -1 : 0;
    })
  },

  group_by_date: (sessions) => {
    const date = item => moment(item.date).format('YYYY-MM-DD');
    return _.groupBy(sessions, date)
  },

  group_by_roomName: (sessions) => {
    const roomName = item => item.roomName;
    return _.groupBy(sessions, roomName);
  },

  by_starting_time: (array) => {
    return array.sort((a, b) => {
      return moment(a.starting).diff(b.starting);
    })
  },
}