export const Scroll = {
  toLocationHashId: () => {
    const target = window.location.hash !== '' ? window.location.hash : null;
    if (target) {
      const element = document.querySelector(target);
      if(element) {
        const offset = element.offsetTop;
        window.scrollTo(0, offset)
      }
    }
  },

  toSpecifiedSectionId: (idSelector) => {
    const element = document.querySelector(`${idSelector}`);
    if(element) {
      const offset = element.offsetTop;
      window.scrollTo(0, offset)
    }
  }
};
