import {Model} from '@itsfadnis/jsonapi-client';
import {Lecturers} from './Lecturers';

export class LecturerConnections extends Model {
  static _type = 'conference_lecturer_connections';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.priorityShow = args.priorityShow;
    this.lft = args.lft;

    this.lecturer = this.hasOne(Lecturers, args.lecturer)
  }
}