export const Filter = {
  arrayByType : (array, type) => {
    return array.filter(data => {
      return data.type === type
    })
  },

  byPosition: (array, position) => {
    return array.find(data => {
      return Number(data.position) === Number(position)
    })
  },

  eventsOnMainPage : (sessions) => {
    return sessions.flatMap(session => {
      return session.events.filter(event => event.showOnMainPage)
    })
  }
};