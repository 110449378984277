import React, {Component} from 'react';
import MTLogo from '../../assets/images/M_T_PL_black.png';


export default class CongressPromoter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      short: true
    }
  }

  _toggleText = () => {
    this.setState({
      short: !this.state.short,
    });
  };

  render() {
    const text = `<p>Wydawnictwo Medical Tribune Polska od 1991 roku zajmuje się profesjonalną edukacją podyplomową lekarzy.</p>
                  Naszą misją jest dostarczanie użytecznych, wiarygodnych, praktycznych wiadomości, przydatnych w codziennej pracy lekarza.<br/>
                  Od 2003 roku organizujemy jedne z większych kongresów medycznych w Polsce. W naszej ofercie wydawniczej znajduje się kilkaset publikacji i kilkanaście pism specjalistycznych, serwisy internetowe oraz aplikacje mobilne.<br/>
                  Piszą dla nas i wykładają na naszych kongresach najlepsi specjaliści w swoich dziedzinach.`
    let shortText = text.substr(0, 250) + '...';

    return (
        <section className='promoter-section page__section' id='promoter'>
          <h3 className='section-title'>
            Organizator
          </h3>
          <div className="grid__container">
            <div className="grid__row">
              <div className="grid__col_12 grid__col_lg_4">
                <img src={MTLogo} alt="Logo Medical Tribune Polska" className='promoter-section__img'/>
              </div>
              <div className="grid__col_12 grid__col_lg_8">
                <div className="promoter-section__description">
                  <p className='promoter-section__paragraph' dangerouslySetInnerHTML={{__html: this.state.short ? shortText : text}}/>
                  <button className='button-details' onClick={this._toggleText}>
                    {this.state.short ? 'Przeczytaj całość' : 'Zwiń opis'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
  }
}