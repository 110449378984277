import { Model } from '@itsfadnis/jsonapi-client';

export class HeaderPodyplomieSubmenu extends Model {
  static _type = 'menus';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.title = args.title;
    this.url = args.url;
    this.classification = args.classification;
    this.position = args.position;
    this.showInMenu = args.showInMenu;
    this.inNewWindow = args.inNewWindow;
    this.lft = args.lft;
    this.rgt = args.rgt;
    this.newEdition = args.newEdition;
    this.recommend = args.recommend;
    this.promotion = args.promotion;
    this.content = args.content;

    this.badgeType = this.getSpecialProperties(this);
  }

  BADGE_ATTRIBUTES = Object.freeze(['newEdition', 'promotion', 'recommend']);

  getSpecialProperties = (item) => {
    return this.BADGE_ATTRIBUTES.filter(attribute => item[attribute] !== false);
  }
}