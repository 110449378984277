import React, {Component} from 'react';
import {Validator} from '../../../helpers/validator'
import Api from '../../../api/Api';
import {SetFormData} from '../../../helpers/form_data_setter';
import FormSendButton from './form_send_button';

export default class ContactFormCongress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      email: '',
      phone: '',
      messageSent: false
    };

    this.messageFormat = /^(?!\s*$).+/;
    this.emailFormat = /^[0-9a-z_.-]+@[0-9a-z.-]+\.[a-z]{2,3}$/;
    this.phoneFormat = /[0-9-\s]{9,12}/;

    this.formValid = () => {
      const {message, email, phone} = this.state;

      const messageValid = Validator.validateFormat(message, this.messageFormat);
      const emailValid = Validator.validateFormat(email, this.emailFormat);
      const phoneValid = Validator.validateFormat(phone, this.phoneFormat);

      return messageValid && (emailValid || phoneValid);
    };
  }

  _toggleMessageState = () => {
    this.setState({
      messageSent: true,
    });
  };

  _handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
      messageSent: false
    });
  };

  _handleFormSubmit = (e) => {
    e.preventDefault();
    if(this.state.messageSent) {
      return false;
    }
    const formData = new FormData();
    SetFormData.fromState(this.state, formData)
    formData.append('data[conference_info_id]', this.props.conferenceId);
    Api
        .sendContactForm(this.props.conferenceId, formData)
        .then(() => {
              this._toggleMessageState()
        })
        .catch(error => console.error(error))
  };

  render() {
    const formValid = this.formValid();
    return (
        <div>
          <form action='' id='contactForm' method='post' className='contact-form' onSubmit={this._handleFormSubmit}>
            <label htmlFor='formText' className='form__label'>Twoja wiadomość</label>
            <textarea className='form__input form__textarea input textarea'
                      id='formText'
                      name='message'
                      placeholder='Twoja wiadomość*'
                      rows='8'
                      cols='40'
                      data-name="textarea"
                      onChange={this._handleInputChange}
                      required/>
            <label htmlFor='formEmail' className='form__label'>Twój e-mail</label>
            <input className='form__input input'
                   id='formEmail'
                   name='email'
                   type='email'
                   placeholder='Twój adres e-mail'
                   autoComplete='on'
                   data-name='email'
                   onChange={this._handleInputChange}
            />
            <label htmlFor='formPhone' className='form__label'>Telefon komórkowy</label>
            <input className='form__input input'
                   id='formPhone'
                   name='phone'
                   type='tel'
                   placeholder='Twój numer telefonu'
                   autoComplete='on'
                   data-name="phone"
                   onChange={this._handleInputChange}
            />

            <p className='contact-form__text contact-form__text_lg'>Podaj jedną z form kontaktu zwrotnego</p>
            <p className='contact-form__text contact-form__text_md'>Zgłoszenie będzie przetwarzane w celu kontaktu
              bezpośredniego drogą elektroniczną lub telefoniczną</p>
            <p className='contact-form__text contact-form__text_sm'>Administratorem danych osobowych (ADO) jest
              Medical Tribune Polska Sp. z o.o. Więcej informacji o administratorze i sposobie przetwarzania danych
              zawartych jest w naszej <a href="https://www.podyplomie.pl/polityka-prywatnosci" className='link_black' target='_blank' rel='noopener noreferrer'>Polityce Prywatności.</a></p>

            <FormSendButton messageSent={this.state.messageSent} formValid={formValid}/>
            {!this.state.messageSent && !formValid &&
              <span className='form__error'>Wypełnij wymagane pola</span>
            }
          </form>
        </div>
    )
  }
}