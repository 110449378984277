import React, {Component} from 'react';
import {Helmet} from 'react-helmet';

export default class SeoTags extends Component {
  render() {
    const {metaData, type} = this.props;

    if (type === 'single') {
      return (
          <Helmet>
            <title>{metaData.title}</title>
            <meta property="og:locale" content="pl_PL"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content={metaData.seoTitle}/>
            <meta property="og:description" content={metaData.seoDescription}/>
            <meta property="og:url" content={window.location.url}/>
            <meta property="og:site_name" content="Podyplomie"/>
            <meta property="article:tag" content={metaData.title}/>
            <meta property="article:tag" content={metaData.specialization}/>
            <meta property="article:published_time" content={metaData.createdAt}/>
            <meta property="article:modified_time" content={metaData.updatedAt}/>
            <meta property="og:updated_time" content={metaData.updatedAt}/>

            <meta property="og:image" content={metaData.firstSlider}/>
            <meta property="og:image:width" content="735"/>
            <meta property="og:image:height" content="427"/>
            <meta property="og:image" content={metaData.secondSlider}/>
            <meta property="og:image:width" content="735"/>
            <meta property="og:image:height" content="427"/>

            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:description" content={metaData.description}/>
            <meta name="twitter:title" content={metaData.seoTitle || metaData.title}/>
            <meta name="twitter:image" content={metaData.firstSlider}/>
            <meta name="twitter:domain" content={metaData.base_url}/>

            <link itemProp="url" href={metaData.base_url}/>
            <meta itemProp="name" content={metaData.seoTitle || metaData.title}/>
            <meta itemProp="description" content={metaData.seoDescription}/>

            <meta name="description" content={metaData.seoDescription}/>
            <meta name="thumbnail" content={metaData.firstSliderThumb}/>
            <meta name="theme-color" content="#000000" />
            <meta name="robots" content="index, follow"/>

            <noscript itemProp="image" itemScope itemType="https://schema.org/ImageObject">
              {`<link itemProp="url" href={${metaData.firstSlider}}/>
                <meta itemProp="width" content="735"/>
                <meta itemProp="height" content="427"/>`}
            </noscript>

          </Helmet>
      )
    } else {
      return (
          <Helmet>
            <title>{metaData.title}</title>
            <meta property="og:locale" content="pl_PL"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content={metaData.title}/>
            <meta property="og:description" content={metaData.description}/>
            <meta property="og:url" content={window.location.url}/>
            <meta property="og:site_name" content="Podyplomie"/>
            <meta property="article:tag" content={metaData.title}/>

            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:description" content={metaData.description}/>
            <meta name="twitter:title" content={metaData.title}/>
            <meta name="twitter:domain" content={metaData.base_url}/>

            <link itemProp="url" href={metaData.base_url}/>
            <meta itemProp="name" content={metaData.title}/>
            <meta itemProp="description" content={metaData.description}/>

            <meta name="description" content={metaData.description}/>
            <meta name="theme-color" content="#000000" />
            <meta name="robots" content="index, follow"/>
          </Helmet>
      )
    }

  }
}