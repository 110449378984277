import React, { Component } from 'react';
import Workshop from './workshop';
import AddToCartForm from './add_to_cart_form';

export default class WorkshopList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedProducts: [],
      fullPrice: 0,
      allProductsSent: false,
      sentProducts: [],
      productHasJustBeenSent: false
    }
  }

  _mergeWithUniqueValues = (array) => {
    return [...new Set([].concat(...array))]
  };

  _countFullPrice = (products) => {
    return products
      .map(p => Number(p.basePrice))
      .reduce(function(a, b) { return a + b})
  };

  _deleteProductFromChecked = (product) => {
    const currentCheckedProducts = [...this.state.checkedProducts];
    currentCheckedProducts.splice(this.state.checkedProducts.indexOf(product), 1);
    this.setState({ checkedProducts: currentCheckedProducts });
  };

  _setCheckedCheckboxes = (product, shouldBeChecked=true) => {
    shouldBeChecked
      ? this.setState({ checkedProducts: [...this.state.checkedProducts, product] })
      : this._deleteProductFromChecked(product)
  };

  _setCheckedRadio = (product) => {
    this.setState({checkedProducts: [product]})
  };

  _rejectDuplicates = (array1, array2) => {
    return array1.filter(product => !array2.includes(product))
  }

  _updateSentProducts = () => {
    const sentProducts = this._mergeWithUniqueValues([this.state.checkedProducts, this.state.sentProducts]);
    if (sentProducts.length !== this.state.sentProducts.length) {
      this.setState({
        sentProducts: sentProducts,
        productHasJustBeenSent: true
      });
      setTimeout(() => this.setState({productHasJustBeenSent: false}), 2000)
    }
  };

  _verifySentProducts = () => {
    this.setState({ allProductsSent: !this.props.allowMultipleWorkshops ? true : this.state.sentProducts.length === this.props.workshopProducts.length })
  };

  handleSubmit = () => {
    const productsToSend = this._rejectDuplicates(this.state.checkedProducts, this.state.sentProducts);
    return Promise
      .all(productsToSend.map(product => product.sendToCart(1)))
      .then(() => this._updateSentProducts())
      .then(() => this._verifySentProducts())
  };

  setChecked = (product, shouldBeSent=true) => {
    return this.props.allowMultipleWorkshops
      ? this._setCheckedCheckboxes(product, shouldBeSent)
      : this._setCheckedRadio(product)
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.checkedProducts !== this.state.checkedProducts) {
      this.setState({fullPrice: this.state.checkedProducts.length > 0 ? this._countFullPrice(this.state.checkedProducts) : 0})
    }
  }

  render() {
    const {
      props: { allowMultipleWorkshops, workshopProducts },
      state: {allProductsSent, checkedProducts, sentProducts}
    } = this;

    const inputType = allowMultipleWorkshops ? 'checkbox' : 'radio';

    return (
        <div className='workshops'>
          <p>
            Skorzystaj z warsztatów
          </p>
          <ul className='workshops-list'>
            {workshopProducts.map((workshop, index) =>
              <Workshop
                allProductsSent={allProductsSent}
                sentProducts={sentProducts}
                allowMultipleWorkshops={allowMultipleWorkshops}
                workshop={workshop}
                key={index}
                setChecked={this.setChecked}
                checkedProducts={checkedProducts}
                inputType={inputType}
              />
            )}
          </ul>
          <div className='workshops__summary'>
            <AddToCartForm
              handleSubmit={this.handleSubmit}
              productsSent={allProductsSent}
              withTooltip
              tooltipVisible={this.state.productHasJustBeenSent}
            />
            {/*Hidden element due to possible use in the future*/}
            {/*<div className='workshops__sum'>*/}
            {/*  <span>*/}
            {/*    Cena całkowita:*/}
            {/*  </span>*/}
            {/*  <span className='workshops__amount '>*/}
            {/*   {this.state.fullPrice} zł*/}
            {/*  </span>*/}
            {/*</div>*/}
          </div>
        </div>
    )
  }
}