import {Model} from '@itsfadnis/jsonapi-client';
import {ConferenceInvitationLecturersConnections} from './ConferenceInvitationLecturersConnections';
import {Lecturers} from './Lecturers';

export class ConferenceSectionInvitation extends Model {
  static _type = 'conference_section_invitation';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.htmlContent = args.htmlContent;
    this.lecturerConnections = this.hasMany(ConferenceInvitationLecturersConnections, args.lecturerConnections)
    this.lecturers = this.hasMany(Lecturers, args.lecturers)
  }
}