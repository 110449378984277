import React, {Component} from 'react';

export class Patronage extends Component {
  render() {
    const {data} = this.props;
    const patron = data.patronats[0]

    return (
        <div className='patronage'>
          <div>
            <img src={patron.mediumImage} alt="Logo patrona" className='patronage__image'/>
          </div>
          <div className='patronage__texts'>
            {patron.title &&
            <p className='patronage__title'>{patron.title}</p>
            }
            {patron.description &&
            <p>{patron.description}</p>
            }
          </div>
        </div>
    )
  }
}