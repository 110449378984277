import {Model} from '@itsfadnis/jsonapi-client'
import {Lecturers} from './Lecturers';

export class ConferenceInvitationLecturersConnections extends Model {
  static _type = 'conference_invitations_lecturers_connections';

  constructor(args={}){
    super(args);

    this.id = args.id;
    this.lft = args.lft;

    this.lecturer = this.hasOne(Lecturers, args.lecturer);
  }
}