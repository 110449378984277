import React from 'react';

export default function ArchivedCongress(props) {
  const {data} = props;
  const type = data.url ? 'old' : 'new';

  if (type === 'new') {
    const title = `${data.conferenceInfo.specialization} ${data.conferenceInfo.claim ? '- ' + data.conferenceInfo.claim : ''}`;
    return (
      <a href={`/kongresy/${data.conferenceInfo.slug}/${data.city ? data.city.toLowerCase() : ''}`}
         className='congress-list-archive-card__link'>{title}</a>
    )
  } else {
    return (
      <a href={data.url} className='congress-list-archive-card__link'>{data.name}</a>
    )
  }
}