import React, { Component } from 'react';
import Popup from '../popups/popup';
import PropTypes from 'prop-types'

export default class LectureDescription extends Component {
  static propTypes = {
    description: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      lecturePopupOpen: false
    }
  }

  _togglePopup = () => {
    this.setState({
      lecturePopupOpen: !this.state.lecturePopupOpen
    })
  };

  render() {
    const { description } = this.props;
    return (
      <>
        <button className='button-details button-details_bold button-flex' onClick={this._togglePopup}>
          Dowiedz się więcej
          <span className='arrow arrow-sm arrow-sm_green arrow-sm_down'/>
        </button>
        <Popup data={description}
               closePopup={this._togglePopup}
               type='lecture'
               popupShow={this.state.lecturePopupOpen}
        />
      </>
    )
  }
}