import {Model} from '@itsfadnis/jsonapi-client';
import {ConferenceSecionPreviousEditionComponentTexts} from './ConferenceSecionPreviousEditionComponentTexts';

export class ConferenceSectionPreviousEditionComponents extends Model {
  static _type = 'conference_section_previous_edition_components';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.view = args.view;
    this.position = args.position;
    this.mediumImage = args.mediumImage;
    this.thumbImage = args.thumbImage;
    this.title = args.title;
    if (args.texts !== null) {
      this.texts = this.hasMany(ConferenceSecionPreviousEditionComponentTexts, args.texts)
    }
    if (args.source !== null) {
      this.source = args.source;
    }

  }
}