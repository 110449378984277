import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {PageHelper} from './helpers/page_helper';
import CongressView from './components/congress_single/congress_view';
import CongressList from './components/congress_list/congress_list';
import ErrorBoundary from './components/error_boundaries/base_error_boundary';
require('dotenv').config();

export default class App extends Component {
  constructor(props) {
    super(props);
    this._isSmallView = PageHelper.isSmallView.bind(this);
    this._isMobileView = PageHelper.isMobileView.bind(this);
  }

  _isSmallView = () => {
    PageHelper.isSmallView();
  };

  _isMobileView = () => {
    PageHelper.isMobileView();
  };

  render() {
    window.isSmallView = this._isSmallView();
    window.isMobileView = this._isMobileView();
    return (
        <Router >
          <div className="App">
              <Route
                  exact
                  path='/kongresy'
                  render={({match}) => (
                      <ErrorBoundary>
                        <CongressList
                          match={match}
                        />
                      </ErrorBoundary>
                  )}
              />

              <Route
                  path='/kongresy/:slug/:city?'
                  render={({match}) => (
                      <ErrorBoundary>
                        <CongressView
                          match={match}
                        />
                      </ErrorBoundary>
                  )}
              />
          </div>
        </Router>
    );
  }
}
