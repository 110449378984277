import React, {Component} from 'react';
import Popup from '../popups/popup';

export class CongressProgramWorkshopsEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extended: false,
      popupVisibleFor: null
    }
  }

  _togglePopup = (e) => {
    this.setState({
      popupVisibleFor: e.target.dataset.id
    })
  };

  _closePopup = () => {
    this.setState({
      popupVisibleFor: null
    })
  }

  _toggleDescription = () => {
    this.setState({extended: !this.state.extended})
  };

  render() {
    const {
      props: {event, properIcon},
      state: {extended}
    } = this;

    const {timeOfEvent} = event;
    const isLogoPresent = event.thumbLogo !== '/images/thumb/missing.png';

    return (
        <div className='congress-lecture'>
          {properIcon &&
            <div className='grid__col_2 grid__col_sm_1'>
              {properIcon(event.eventType)}
            </div>
          }
          <div className='grid__col_10 grid__col_sm_11 congress-lecture__term'>
            <div className="congress-lecture__time">
              {timeOfEvent}
            </div>
            <div className="congress-lecture__title-row grid__row">
              <div className='grid__col_12 grid__col_sm_8'>
                <p className='congress-lecture__title '>{event.title}</p>
              </div>
              {isLogoPresent &&
                  <div className='congress-lecture__logo-container grid__col_12 grid__col_sm_4'>
                    <img src={event.thumbLogo} alt='Sponsor warsztatu' className='congress-lecture__sponsors-logo'/>
                  </div>
              }
            </div>

            {event.lecturers.length > 0 && event.lecturers.map((lecturer, index) =>
                <div className="congress-lecture__lecturer" key={index} onClick={(e) => {this._togglePopup(e)}} data-id={lecturer.id}>
                  {lecturer.nameWithDegree}
                  {!!this.state.popupVisibleFor &&
                  <Popup data={lecturer} 
                  type='lecturer' 
                  popupShow={lecturer.id == this.state.popupVisibleFor} 
                  closePopup={this._closePopup}/>
                  }
                </div>
            )}

            <button
                className='button button-flex button_no-padding congress-program__text congress-program__text_small congress-program__text_success congress-lecture__button'
                onClick={this._toggleDescription}
            >
              {extended ? 'Zwiń szczegóły' : 'Dowiedz się więcej'}
              <span
                  className={`arrow arrow-sm ${extended ? 'arrow-sm_up' : 'arrow-sm_down'} congress-program__arrow-success`}/>
            </button>
            <div className={`congress-lecture__details ${extended ? '' : 'congress-lecture__details_hide'}`}>
              <div className="congress-lecture__description">
                {event.description}
              </div>
              <div className="congress-lecture__price">
                Cena warsztatów: {Number(event.price)} zł
              </div>
              <div className="congress-lecture__description">
                Zapisy na warsztaty: {event.enrollInfo}
              </div>
            </div>
          </div>
        </div>

    )
  }
}