import {Model} from '@itsfadnis/jsonapi-client';
import {ConferenceSectionAgendaSessions} from './ConferenceSectionAgendaSessions';
import {ConferenceSectionWorkshopSessions} from './ConferenceSectionWorkshopSessions';
import { Sort } from '../../../helpers/sort';
import { Filter } from '../../../helpers/filter';

export class ConferenceSectionAgenda extends Model {
  static _type = 'conference_section_agendas'

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.pdfFile = args.pdfFile;

    this.agendaSessions = this.hasMany(ConferenceSectionAgendaSessions, args.agendaSessions)
    this.agendaWorkshops = this.hasMany(ConferenceSectionWorkshopSessions, args.agendaWorkshops)
    this.agendaSessionsByTime = this.sort_by_starting_time(this.agendaSessions)
    this.agendaWorkshopsByTime = this.sort_by_starting_time(this.agendaWorkshops)

    this.sessions = Sort.group_by_date(this.agendaSessionsByTime);
    this.workshops= Sort.group_by_date(this.agendaWorkshopsByTime);
    this.sessionsByRoomNames = Sort.group_by_roomName(this.agendaSessionsByTime);

    this.mainPageWorkshops = Filter.eventsOnMainPage(this.agendaWorkshopsByTime);
    this.mainPageLectures = Filter.eventsOnMainPage(this.agendaSessionsByTime);

    this.sessionDates = Object.keys(this.sessions);
    this.workshopDates = Object.keys(this.workshops);
    this.sessionsRoomNames = Object.keys(this.sessionsByRoomNames)[0] !== 'null' ? Object.keys(this.sessionsByRoomNames) : [];
  }

  sort_by_starting_time = (events) => {
    return Sort.by_starting_time(events)
  }
}