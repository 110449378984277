import React, {Component} from 'react';
import MenuFixed from "./menu_fixed";
import Header from "./header";


export default class Hero extends Component {
  render() {
    const {currentCityId, changeCurrentCityId, conference, isCongressFuture, currentPlace} = this.props;
    return (
        <div className='hero'>
          <div className="grid__container">
            <div className="grid__row">
              <div className="grid__col_12 grid__col_lg_3">
                <MenuFixed
                    currentCityId={currentCityId}
                    conference={conference}
                    changeCurrentCityId={changeCurrentCityId}
                    isCongressFuture={isCongressFuture}
                    currentPlace={currentPlace}
                />
              </div>
              <div className="grid__col_12 grid__col_lg_9">
                <Header
                    currentCityId={currentCityId}
                    conference={conference}
                    currentPlace={currentPlace}
                    isCongressFuture={isCongressFuture}
                />
              </div>
            </div>
          </div>
        </div>
    )
  }
}