import React, {Component} from 'react';
import Slider from "react-slick";
import HeaderBackgroundImage from './HeaderBackgroundImage';

export default class HeaderSlider extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoPlay: true
    };

    const { sliderData, conference, currentPlace } = this.props;

    return (
        <div className='grid__negative-margin-right header__slider'>
          <Slider {...settings}>
            {sliderData.sliders.length > 0 && sliderData.sliders.map((slide, index) =>
                <div className='header__slider_el' key={index}>
                  <HeaderBackgroundImage
                    sliders={sliderData.sliders}
                    slug={conference.slug}
                    city={currentPlace.city}
                    className={'header__slider-img'}
                  />
                </div>
            )}
          </Slider>
        </div>
    )
  }
}

