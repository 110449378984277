import React, {Component} from 'react';
import {FooterPodyplomieMenu} from '../../models/congress_single/entity/FooterPodyplomieMenu';

export default class FooterPodyplomie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      footerElements: [],

    }
  }

  componentDidMount() {
    FooterPodyplomieMenu.fetch('/menus/footer?include=subMenus')
        .then(resp => {
          this.setState({
            footerElements: resp
          })
        })
  }


  render() {
    const {footerElements} = this.state;

    return (
        <div>
          {footerElements.length > 0 &&
            <section className='footer-podyplomie'>
              <div className='grid__container'>
                <div className="grid__row">
                  <div className="grid__col_12 grid__col_md_3">
                    <RenderFooterList element={footerElements[0]}/>
                  </div>
                  <div className="grid__col_12 grid__col_md_3">
                    <RenderFooterList element={footerElements[1]}/>
                    <RenderFooterList element={footerElements[2]}/>
                  </div>
                  <div className="grid__col_12 grid__col_md_3">
                    <RenderFooterList element={footerElements[3]}/>
                  </div>
                  <div className="grid__col_12 grid__col_md_3">
                    <RenderFooterList />
                  </div>
                </div>
              </div>

            </section>
          }
        </div>
    )
  }
}

class RenderFooterList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      extended: false,
    }
  }

  _toggleFooterElement = () => {
    this.setState({
      extended: !this.state.extended
    })
  }

  render() {
    const {element} = this.props;
    const isMobileView = window.isMobileView;

    if (element) {
      return (
          <div className='footer-podyplomie__section'>
            <div className='footer-podyplomie__title-row' onClick={this._toggleFooterElement}>
              <h4 className='footer-podyplomie__title'>
                {element.title}
              </h4>
              {isMobileView &&
              <span className={`arrow arrow-sm ${this.state.extended ? 'arrow-sm_up' : 'arrow-sm_down'}`}/>
              }
            </div>


            <ul className={`footer-podyplomie__items ${this.state.extended ? 'footer-podyplomie__items_show' : ''}`} >
              {element.subMenus.length > 0 && element.subMenus.filter(item => item.showInMenu == true).map((subElement, index) =>
                  <li className='footer-podyplomie__item' key={index}>
                    <a href={subElement.url} target={subElement.inNewWindow ? '_blank' : ''} className='footer-podyplomie__link'>
                      {subElement.title}
                    </a>
                  </li>
              )}
            </ul>
          </div>
      )
    } else {
      return (
          <div className="footer-podyplomie__section">
            <div className='footer-podyplomie__title-row' onClick={this._toggleFooterElement}>
              <h4 className='footer-podyplomie__title' >
                Kontakt
              </h4>
              {isMobileView &&
              <span className={`arrow arrow-sm ${this.state.extended ? 'arrow-sm_up' : 'arrow-sm_down'}`}/>
              }
            </div>
            <div className={`footer-podyplomie__items ${this.state.extended ? 'footer-podyplomie__items_show' : ''}`}>
              <p className="footer-podyplomie__text">
                Medical Tribune Polska Sp. z o.o.
              </p>
              <p className="footer-podyplomie__text">
                ul.Grzybowska 87
              </p>
              <p className="footer-podyplomie__text">
                00-844 Warszawa
              </p>
              <p className="footer-podyplomie__text">
                NIP:521-008-60-45
              </p>

              <div className='footer-podyplomie__contacts'>
                <a href="tel:801044415" className='footer-podyplomie__link footer-podyplomie__link_white'>
                  801 044 415
                </a>
                <a href="tel:+48224442444" className='footer-podyplomie__link footer-podyplomie__link_white'>
                  +48 (22) 444 24 44
                </a>
                <a href="mailto:kontakt@podyplomie.pl" className='footer-podyplomie__link'>
                  kontakt@podyplomie.pl
                </a>
                <br/>
                <p className='footer-podyplomie__text'>
                  Kontakt w sprawie reklam:
                </p>
                <a href="mailto:reklama@podyplomie.pl" className='footer-podyplomie__link'>
                  reklama@podyplomie.pl
                </a>
              </div>
            </div>
          </div>
      )
    }

  }
}