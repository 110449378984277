import {Model} from '@itsfadnis/jsonapi-client';
import {ConferenceSectionInvitation} from './ConferenceSectionInvitation';
import {ConferenceSectionAgenda} from './ConferenceSectionAgenda';
import {ConferenceSectionSummaries} from './ConferenceSectionSummaries';
import {ConferenceSectionLecturer} from './ConferenceSectionLecturer';
import {ConferenceSectionPracticalInformations} from './ConferenceSectionPracticalInformations';
import {ConferenceSectionTickets} from './ConferenceSectionTickets';
import {ConferenceSectionLiveTransmissions} from './ConferenceSectionLiveTransmissions';
import {ConferenceSectionEntertainmentEvenings} from './ConferenceSectionEntertainmentEvenings';
import {ConferenceSectionPackageInfos} from './ConferenceSectionPackageInfos';
import {ConferenceSectionPreviousEditions} from './ConferenceSectionPreviousEditions';
import {ConferenceSectionExhibitors} from './ConferenceSectionExhibitors';
import {ConferenceSectionSponsors} from './ConferenceSectionSponsors';
import {ConferenceSectionPatronats} from './ConferenceSectionPatronats';
import {ConferenceSectionVideoInvitations} from './ConferenceSectionVideoInvitations';

export class Section extends Model {
  static _type = 'conferenceSections';
  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.title = args.title;
    this.titleMenu = args.titleMenu;
    this.showInMenu = args.showInMenu;
    this.classification = args.classification;
    this.lft = args.lft;
    this.rgt = args.rgt;

    this.agendas = this.hasMany(ConferenceSectionAgenda, args.agendas);
    this.summaries = this.hasMany(ConferenceSectionSummaries, args.summaries);
    this.lecturers = this.hasMany(ConferenceSectionLecturer, args.lecturers);
    this.practicalInformations = this.hasMany(ConferenceSectionPracticalInformations, args.practicalInformations);
    this.tickets = this.hasMany(ConferenceSectionTickets, args.tickets);

    if (args.invitations !== null) {
      this.invitations = this.hasMany(ConferenceSectionInvitation, args.invitations);
    }

    if (args.previousEditions !== null) {
      this.previousEditions = this.hasMany(ConferenceSectionPreviousEditions, args.previousEditions)
    }

    if (args.liveTransmissions !== null) {
      this.liveTransmissions = this.hasMany(ConferenceSectionLiveTransmissions, args.liveTransmissions)
    }

    if (args.entertainmentEvenings !== null) {
      this.entertainmentEvenings = this.hasMany(ConferenceSectionEntertainmentEvenings, args.entertainmentEvenings);
    }

    if (args.packageInfos !== null) {
      this.packageInfos = this.hasMany(ConferenceSectionPackageInfos, args.packageInfos)
    }

    if (args.exhibitors !== null) {
      this.exhibitors = this.hasMany(ConferenceSectionExhibitors, args.exhibitors)
    }

    if(args.sponsors !== null) {
      this.sponsors = this.hasMany(ConferenceSectionSponsors, args.sponsors)
    }

    if(args.patronats !== null) {
      this.patronats = this.hasMany(ConferenceSectionPatronats, args.patronats)
    }

    if(args.videoInvitations !== null) {
      this.videoInvitations = this.hasMany(ConferenceSectionVideoInvitations, args.videoInvitations)
    }
  }
}
