import {Model} from '@itsfadnis/jsonapi-client';

export class ConferenceSectionPatronats extends Model {
  static _type = 'conference_section_patronats';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.mediumImage = args.mediumImage;
    this.thumbImage = args.thumbImage;
    this.title = args.title;
    this.description = args.description;
  }
}