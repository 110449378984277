import Api, { base_url } from '../api/Api';

export const buildAddToCartUrl = (id, quantity) => {
  return (window.location.port === '' ? window.location.origin : base_url) + `/products/${id}/add_to_cart/?quantity=${quantity}`;
};

export const buildArrayOfUrls = (ids, quantity) => {
  return ids.map((id) => buildAddToCartUrl(id, quantity))
};

export const sendToCart = (ids, quantity) => {
  const arrayOfUrls = buildArrayOfUrls(ids, quantity);
  async function sendApiRequestsAsynchronously() {
    for (let url of arrayOfUrls) {
      await Api.sendProducts(url)
    }
  }

  return sendApiRequestsAsynchronously();
};
