import React, {Component} from 'react';
import {Validator} from '../../../helpers/validator';
import Api from '../../../api/Api';
import FormSendButton from './form_send_button';

export default class NewsletterFormCongress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      messageSent: false
    };

    this.emailFormat= /^[0-9a-z_.-]+@[0-9a-z.-]+\.[a-z]{2,3}$/;

    this.formValid = () => {
      let {email} = this.state;

      let emailValid = Validator.validateFormat(email, this.emailFormat);

      return emailValid;
    };
  }


  _toggleMessageState = () => {
    this.setState({
      messageSent: true,
    });
  };

  _handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
      messageSent: false,
    });
  };

  _handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.state.messageSent) {
      return false;
    }
    const formData = new FormData();
    formData.append('data[email]', this.state.email);
    formData.append('data[from]', 'from_this_conference');
    formData.append('data[conference_info_id]', this.props.conferenceId);
    Api
        .sendNewsletterForm(this.props.conferenceId, formData)
        .then(() => {
          this._toggleMessageState();
        })
        .catch(error => console.error(error));
  };

  render() {
    const formValid = this.formValid();
    return (
        <form action='' method='post' className='newsletter-form' id='newsletterForm'
              onSubmit={this._handleFormSubmit}>
          <label htmlFor='newsletterFormEmail' className='form__label'>
            Twój email
          </label>
          <input type='email'
                 className='input form__input'
                 placeholder='Twój e-mail*'
                 id='newsletterFormEmail'
                 name='email'
                 onChange={this._handleInputChange}
                 required
          />
          <p className='contact-form__text contact-form__text_sm'>Administratorem danych osobowych (ADO) jest
            Medical Tribune Polska Sp. z o.o. Więcej informacji o administratorze i sposobie przetwarzania danych
            zawartych jest w naszej <a href="https://www.podyplomie.pl/polityka-prywatnosci" className='link_black' target='_blank' rel='noopener noreferrer'>Polityce Prywatności.</a></p>
          <FormSendButton messageSent={this.state.messageSent} formValid={formValid}/>
          {!this.state.messageSent && !formValid &&
            <span className={`form__error`}>Wypełnij wymagane pola</span>
          }
        </form>
    )
  }
}