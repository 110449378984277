import React, {Component} from 'react';
import Slick from 'react-slick';

export default class CongressExhibitors extends Component {
  render() {
    const isMobileView = window.isMobileView;
    const {data}= this.props;
    const logos = data.exhibitors[0].logos;

    const settings = {
      infinite: logos.length > 4,
      speed: 500,
      slidesToShow: isMobileView ? 2 : 4,
      slidesToScroll: 1,
      dots: true,
      arrows: false
    };

    return (
        <div className='congress-exhibitors page__section' id='exhibitor'>
          {data.exhibitors.length > 0 &&
            <div>
              <h3 className='section-title'>
                Wystawcy
              </h3>
              <div className="congress-exhibitors__row">
                <Slick {...settings}>
                  {logos.map((exhibitor, index) =>
                      <img src={exhibitor.mediumImage} alt={`Logo ${exhibitor.title}`} key={index} className='congress-exhibitors__logo'/>
                  )}
                </Slick>
              </div>
            </div>
          }
        </div>
    )
  }
}