import {Model} from '@itsfadnis/jsonapi-client';

export class Lecturers extends Model {
  static _type = 'lecturers';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.photo = args.photo;
    this.mediumPhoto = args.mediumPhoto;
    this.thumbPhoto = args.thumbPhoto;
    this.firstName = args.firstName;
    this.lastName = args.lastName;
    this.degree = args.degree;
    this.nameWithDegree = args.nameWithDegree;
    this.videoUrl = args.videoUrl;
    this.description = args.description;
  }
}