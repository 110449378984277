import React, {Component} from 'react';

export class Arrow extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} viewBox="0 0 10 7" className={this.props.className}>
          <path d="M7.705,6.252L4.985,3.257,2.335,6.3"/>
        </svg>
    )
  }
}

export class Calendar extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} className={this.props.className} viewBox="0 0 16.875 17.999">
          <g transform="translate(-16)">
            <g transform="translate(16)">
              <rect className="a" width="2.25" height="2.25" transform="translate(5.625 6.75)"/>
              <rect className="a" width="2.25" height="2.25" transform="translate(9 6.75)"/>
              <rect className="a" width="2.25" height="2.25" transform="translate(12.375 6.75)"/>
              <rect className="a" width="2.25" height="2.25" transform="translate(2.25 10.125)"/>
              <rect className="a" width="2.25" height="2.25" transform="translate(5.625 10.125)"/>
              <rect className="a" width="2.25" height="2.25" transform="translate(9 10.125)"/>
              <rect className="a" width="2.25" height="2.25" transform="translate(12.375 10.125)"/>
              <rect className="a" width="2.25" height="2.25" transform="translate(2.25 13.5)"/>
              <rect className="a" width="2.25" height="2.25" transform="translate(5.625 13.5)"/>
              <rect className="a" width="2.25" height="2.25" transform="translate(9 13.5)"/>
              <path className="a"
                    d="M30.625,2.25V.562A.563.563,0,0,0,30.062,0h-2.25a.563.563,0,0,0-.562.562V2.25H21.625V.562A.563.563,0,0,0,21.062,0h-2.25a.563.563,0,0,0-.562.562V2.25H16V18H32.874V2.25Zm-2.25-1.125H29.5v2.25H28.375Zm-9,0H20.5v2.25H19.375ZM31.749,16.874H17.125V5.625H31.749Z"
                    transform="translate(-16)"/>
            </g>
          </g>
        </svg>
    )
  }
}

export class ClockIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             viewBox="10 10 60 60" xmlSpace="preserve" className={this.props.className} width={this.props.width}
             height={this.props.height}>
          <title id={this.props.iconTitle}>Clock icon</title>
          <g id="Warstwa_1">
            <g>
              <g id="clock">
                <g>
                  <path d="M40,13c-14.91,0-27,12.09-27,27s12.09,27,27,27s27-12.09,27-27S54.91,13,40,13z M40,63.57
                    c-13.02,0-23.57-10.55-23.57-23.57c0-13.02,10.55-23.57,23.57-23.57c13.02,0,23.57,10.55,23.57,23.57
                    C63.57,53.02,53.02,63.57,40,63.57z M41.69,23.23h-3.32l0.05,18.51l11.65,9.97l2.06-2.9l-10.44-8.75V23.23L41.69,23.23z"/>
                </g>
              </g>
            </g>
          </g>
        </svg>
    )
  }
}

export class CoffeeIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             className={this.props.className} width={this.props.width} height={this.props.height} viewBox="0 0 80 80"
             xmlSpace="preserve">
          <title id={this.props.iconTitle}>Coffee icon</title>
          <g id="Warstwa_1">
            <g>
              <path d="M64.93,42.69h-4.56l0.24-5.06c0.03-0.57-0.18-1.13-0.58-1.55c-0.4-0.42-0.94-0.65-1.52-0.65H12.36
                c-0.57,0-1.12,0.23-1.52,0.65c-0.4,0.42-0.6,0.97-0.58,1.55l0.93,19.54c0.23,4.83,3.19,8.89,7.34,10.74H9.6
                c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1h51.98c1.16,0,2.1-0.94,2.1-2.1c0-1.16-0.94-2.1-2.1-2.1h-9.24
                c3.37-1.5,5.95-4.45,6.93-8.09h5.67c4.72,0,8.57-3.84,8.57-8.57S69.66,42.69,64.93,42.69z M47.3,64.78H23.58
                c-4.38,0-7.98-3.43-8.19-7.81l-0.83-17.34h41.75l-0.83,17.34C55.28,61.35,51.68,64.78,47.3,64.78z M64.93,55.63h-5.18l0.42-8.75
                c0.03,0,0.06,0,0.09,0h4.67c2.41,0,4.37,1.96,4.37,4.37S67.34,55.63,64.93,55.63z"/>
              <path d="M29.27,32c0.33,0.2,0.7,0.29,1.07,0.29c0.72,0,1.42-0.37,1.81-1.03c0.59-1,0.26-2.28-0.74-2.87
                c-1.17-0.69-1.97-1.96-2.21-3.49c-0.25-1.59,0.17-3.18,1.11-4.24c0.01-0.01,0.02-0.02,0.02-0.03c0.23-0.27,0.46-0.53,0.68-0.79
                c1.94-2.23,3.95-4.53,4.52-7.89c0.2-1.14-0.57-2.23-1.71-2.42c-1.14-0.2-2.23,0.57-2.42,1.71c-0.38,2.19-1.85,3.88-3.55,5.84
                c-0.23,0.26-0.46,0.53-0.69,0.8c-1.76,1.99-2.55,4.85-2.11,7.66C25.49,28.32,27.02,30.67,29.27,32z"/>
              <path d="M35.79,27.26c0.69,1.78,1.93,3.42,3.48,4.6c0.38,0.29,0.83,0.43,1.27,0.43c0.63,0,1.26-0.28,1.67-0.83
                c0.7-0.92,0.52-2.24-0.4-2.94c-0.93-0.71-1.7-1.72-2.11-2.78c-0.82-2.11-0.04-4.2,2.59-7.02c1.87-1.99,3.06-4.34,3.45-6.79
                c0.18-1.14-0.6-2.22-1.74-2.4c-1.14-0.18-2.22,0.6-2.4,1.74c-0.26,1.64-1.06,3.19-2.37,4.59C37.85,17.32,33.66,21.79,35.79,27.26z
                "/>
            </g>
          </g>
        </svg>
    )
  }
}

export class DiscussIcon extends Component {
  static defaultProps() {
    return {
      width: '27',
      height: '27',
    }
  }

  render() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 363 363" xmlSpace="preserve" width={this.props.width} height={this.props.height} className={this.props.className}>
          <path d="M277.73,94.123c0,10.997-8.006,17.685-13.852,22.593c-2.214,1.859-6.335,5.251-6.324,6.518
            c0.04,4.97-3.956,8.939-8.927,8.939c-0.025,0-0.05,0-0.075,0c-4.936,0-8.958-3.847-8.998-8.792
            c-0.079-9.747,7.034-15.584,12.75-20.383c4.485-3.766,7.426-6.416,7.426-8.841c0-4.909-3.994-8.903-8.903-8.903
            c-4.911,0-8.906,3.994-8.906,8.903c0,4.971-4.029,9-9,9s-9-4.029-9-9c0-14.834,12.069-26.903,26.904-26.903
            C265.661,67.253,277.73,79.288,277.73,94.123z M248.801,140.481c-4.971,0-8.801,4.029-8.801,9v0.069
            c0,4.971,3.831,8.966,8.801,8.966s9-4.064,9-9.035S253.772,140.481,248.801,140.481z M67.392,203.174c-4.971,0-9,4.029-9,9
            s4.029,9,9,9h0.75c4.971,0,9-4.029,9-9s-4.029-9-9-9H67.392z M98.671,203.174c-4.971,0-9,4.029-9,9s4.029,9,9,9h0.749
            c4.971,0,9-4.029,9-9s-4.029-9-9-9H98.671z M363,59.425v101.301c0,23.985-19.232,43.448-43.217,43.448H203.066
            c-2.282,0-4.161-0.013-5.733-0.046c-1.647-0.034-3.501-0.047-4.224,0.033c-0.753,0.5-2.599,2.191-4.378,3.83
            c-0.705,0.649-1.503,1.363-2.364,2.149l-33.022,30.098c-2.634,2.403-6.531,3.025-9.793,1.587c-3.262-1.439-5.552-4.669-5.552-8.234
            v-95.417H43.72c-14.062,0-25.72,11.523-25.72,25.583v101.301c0,14.061,11.659,25.116,25.72,25.116h130.374
            c2.245,0,4.345,1.031,6.003,2.545L207,317.523v-85.539c0-4.971,4.029-9,9-9s9,4.029,9,9v105.938c0,3.565-2.04,6.747-5.303,8.186
            c-1.167,0.515-2.339,0.718-3.566,0.718c-2.204,0-4.378-0.905-6.069-2.449l-39.457-36.204H43.72c-23.986,0-43.72-19.13-43.72-43.116
            V163.757c0-23.985,19.734-43.583,43.72-43.583H138V59.425c0-23.986,19.885-43.251,43.871-43.251h137.913
            C343.768,16.174,363,35.439,363,59.425z M345,59.425c0-14.061-11.157-25.251-25.217-25.251H181.871
            C167.81,34.174,156,45.364,156,59.425v69.833v83.934l18.095-16.353c0.838-0.765,1.777-1.465,2.462-2.097
            c8.263-7.614,10.377-8.831,21.155-8.609c1.47,0.031,3.221,0.042,5.354,0.042h116.717c14.06,0,25.217-11.388,25.217-25.448V59.425z"
          />
        </svg>
    )
  }
}

export class EmailPendingIcon extends Component {
  static defaultProps() {
    return {
      width: '27',
      height: '27'
    }
  }

  render() {
    return(
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve" width={this.props.width} height={this.props.height} className={this.props.className}>
          <g>
            <g>
              <path d="M488.468,136.274H183.855c-12.976,0-23.532,10.557-23.532,23.532v192.387c0,12.976,10.557,23.532,23.532,23.532h304.613
                c12.976,0,23.532-10.557,23.532-23.532V159.808C512,146.831,501.443,136.274,488.468,136.274z M183.855,151.274h304.613
                c3.575,0,6.639,2.212,7.906,5.338L364.812,258.467c-16.869,13.06-40.434,13.06-57.303,0l-131.56-101.854
                C177.216,153.488,180.28,151.274,183.855,151.274z M497,336.901l-84.102-65.111c-3.275-2.535-7.986-1.936-10.522,1.339
                c-2.536,3.275-1.936,7.986,1.339,10.522l92.658,71.736c-1.267,3.126-4.33,5.339-7.905,5.339H183.855
                c-3.575,0-6.639-2.212-7.906-5.338l92.658-71.736c3.275-2.536,3.875-7.247,1.339-10.522c-2.536-3.275-7.246-3.875-10.522-1.339
                l-84.102,65.111V175.099l123.004,95.229c11.138,8.623,24.486,12.935,37.834,12.935c13.348,0,26.696-4.312,37.834-12.935
                L497,175.099V336.901z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M135.758,272.548H39.564c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h96.194c4.142,0,7.5-3.358,7.5-7.5
                C143.258,275.906,139.9,272.548,135.758,272.548z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M135.758,312.629H7.5c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h128.258c4.142,0,7.5-3.358,7.5-7.5
                C143.258,315.987,139.9,312.629,135.758,312.629z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M135.758,232.469H71.629c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h64.129c4.142,0,7.5-3.358,7.5-7.5
                C143.258,235.826,139.9,232.469,135.758,232.469z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M135.758,192.387h-32.065c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h32.065c4.142,0,7.5-3.358,7.5-7.5
                C143.258,195.745,139.9,192.387,135.758,192.387z"/>
            </g>
          </g>
        </svg>
    )
  }
}


export class FacebookIcon extends Component {
  static defaultProps() {
    return {
      width: '27',
      height: '27'
    }
  }

  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={this.props.width}
             height={this.props.height} viewBox="0 0 27 27" className={this.props.className}>
          <title id={this.props.iconTitle}>Facebook icon</title>
          <image id="facebook-icon.svg" width="27" height="27"
                 xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAB4klEQVRIib2WPUsDQRCGHy+HgjZ2VrFYLOyMYGej5CeIIAaiZUQ7Cysr63RC0lqkkLQWNgF/QuwEZSzSKAo24gcYkJG5cJzJ3SpJXhhYltl5d2d2PibK5TIZmAM2gSJQAPJADugCHaANtICmc+4xzVQamRo9AUpAmHUj4AtoAMfOuU4/hWDAwQpwA+x4EmF6qn8jIhUfMnVPzWTakyQJPVcTEZVc8jZxnNqrhoHIzl6/l1WGSNSzGXdpRKafoepp4NZiswIsm7ym6FdFRO333HjiGaNnYBV4ijacc4hIN+XMtNnfDSyPSp6vOo8RafCXRKRg6zSURGQutIT1/d73sfWGkfvghyewyuCLuLv+mhrF0EpQFrQMbQF3Mb1LYN3WF8BMho1CaD8xCx/AVULnwWTKg0iRDz2Ci6XILPAOfNrepLly3uO8Ihck4jDwVsBLvBoA27Z37UnWDaxNjAOdwPrRONAOrPGNAy0la1rjGyXUfjOwHGqMmKyhI0NU9Y+BtxERvZn9XovRH3nocXABWDNZ9CQ7jGaSeAGuaxXPaKD7Jr6oO+fqkW5yBjkw0mGgbvZ6SLaWrlWJa+vc/xl6NEbqul+XHjTKqaLG5OwPaaF6qr/Yj0iR1jQ1qLvA0VAmYuAb+0F1exw6k1QAAAAASUVORK5CYII="/>
        </svg>
    )
  }
}

export class LectureIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             className={this.props.className} width={this.props.width} height={this.props.height} viewBox="20 20 40 41"
             xmlSpace="preserve">
          <title id={this.props.iconTitle}>Lecture icon</title>
          <g id="Warstwa_1">
            <path d="M27.64,44.1c0,1.83,1.54,3.28,3.34,3.28c1.21,0,2.27-0.66,2.84-1.64h4.16c0.57,0.98,1.63,1.64,2.84,1.64
              s2.27-0.66,2.84-1.64h2.9V54h4.92v-8.26H54v-8.26c0-2.88-2.54-5.19-5.48-4.9c-2.56,0.26-4.42,2.57-4.42,5.13v6.38
              c0-1.81-1.47-3.28-3.28-3.28s-3.28,1.47-3.28,3.28h-3.28c0-1.81-1.47-3.28-3.28-3.28c-0.6,0-1.18,0.16-1.7,0.46V30.92h16.9
              c0.57,0.98,1.63,1.64,2.84,1.64c1.81,0,3.28-1.47,3.28-3.28S50.83,26,49.02,26H26v4.92h1.64V44.1z M45.74,37.72
              c0-1.8,1.29-3.33,2.94-3.5c1.97-0.2,3.68,1.35,3.68,3.26v6.62h-2.52v8.26H48.2V44.1c-0.77,0-1.69,0-2.46,0V37.72z M40.82,42.46
              c0.9,0,1.64,0.74,1.64,1.64s-0.74,1.64-1.64,1.64s-1.64-0.74-1.64-1.64S39.92,42.46,40.82,42.46z M30.98,42.46
              c0.9,0,1.64,0.74,1.64,1.64s-0.74,1.64-1.64,1.64c-0.92,0-1.7-0.75-1.7-1.64S30.06,42.46,30.98,42.46z M50.66,29.28
              c0,0.9-0.74,1.64-1.64,1.64s-1.64-0.74-1.64-1.64s0.74-1.64,1.64-1.64S50.66,28.38,50.66,29.28z M27.64,27.64h18.54
              c-0.28,0.48-0.44,1.04-0.44,1.64h-18.1V27.64z"/>
            <path d="M32.62,32.56h9.84v1.64h-9.84V32.56z"/>
            <path d="M32.62,35.84h9.84v1.64h-9.84V35.84z"/>
            <path d="M45.74,54v-1.7c0-2.91-2.51-5.19-5.43-4.9c-2.56,0.26-4.42,2.57-4.42,5.13V52.3c0-2.88-2.54-5.19-5.48-4.9
              C27.86,47.66,26,49.98,26,52.54V54H45.74z M40.48,49.04c1.99-0.2,3.62,1.36,3.62,3.26v0.05h-6.56
              C37.63,50.64,38.89,49.2,40.48,49.04z M34.26,52.36h-6.61c0.08-1.72,1.34-3.16,2.94-3.32c1.97-0.2,3.68,1.35,3.68,3.26V52.36z"/>
          </g>
        </svg>
    )
  }
}

export class MapMark extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} className={this.props.className} viewBox="0 0 13.037 17.999">
          <g transform="translate(-70.573)">
            <g transform="translate(70.573)">
              <path className="a"
                    d="M77.092,0a6.526,6.526,0,0,0-6.519,6.519c0,4.461,5.834,11.009,6.082,11.286a.587.587,0,0,0,.873,0c.248-.277,6.082-6.825,6.082-11.286A6.526,6.526,0,0,0,77.092,0Zm0,9.8a3.28,3.28,0,1,1,3.28-3.28A3.283,3.283,0,0,1,77.092,9.8Z"
                    transform="translate(-70.573)"/>
            </g>
          </g>
        </svg>
    )
  }
}

export class MealIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 80 80" xmlSpace="preserve" className={this.props.className} width={this.props.width}
             height={this.props.height}>
          <g id="Warstwa_1">
            <g>
              <g>
                <path className="st2" d="M57.43,22.35c1.73-2.2,2.03-4.98,0.87-8.03c-0.83-2.18-2.12-3.74-2.18-3.8c-0.42-0.51-1.18-0.57-1.69-0.15
                  c-0.51,0.42-0.57,1.18-0.15,1.69c0.06,0.07,4.16,5.11,1.26,8.82c-4.19,5.34,0.28,12.38,0.47,12.68c0.23,0.35,0.61,0.55,1.01,0.55
                  c0.22,0,0.45-0.06,0.65-0.19c0.56-0.36,0.71-1.1,0.36-1.66C57.99,32.19,54.3,26.34,57.43,22.35z"/>
              </g>
            </g>
            <g>
              <g>
                <path className="st2" d="M65.47,22.35c1.73-2.2,2.03-4.98,0.87-8.03c-0.83-2.18-2.12-3.74-2.18-3.8c-0.42-0.51-1.18-0.57-1.69-0.15
                  c-0.51,0.42-0.57,1.18-0.15,1.69c0.04,0.05,4.17,5.11,1.26,8.82c-4.19,5.34,0.28,12.38,0.47,12.68c0.23,0.35,0.61,0.55,1.01,0.55
                  c0.22,0,0.45-0.06,0.65-0.19c0.56-0.36,0.71-1.1,0.36-1.66C66.03,32.19,62.34,26.34,65.47,22.35z"/>
              </g>
            </g>
            <g>
              <g>
                <path className="st2" d="M15.18,22.91c1.73-2.2,2.03-4.98,0.87-8.03c-0.83-2.18-2.12-3.74-2.18-3.8c-0.42-0.51-1.18-0.57-1.69-0.15
                  c-0.51,0.42-0.57,1.18-0.15,1.69c0.04,0.05,4.17,5.11,1.26,8.82c-4.19,5.34,0.28,12.38,0.47,12.68c0.23,0.35,0.61,0.55,1.01,0.55
                  c0.22,0,0.45-0.06,0.65-0.19c0.56-0.36,0.71-1.1,0.36-1.66C15.73,32.75,12.05,26.9,15.18,22.91z"/>
              </g>
            </g>
            <g>
              <g>
                <path className="st2" d="M23.22,22.91c1.73-2.2,2.03-4.98,0.87-8.03c-0.83-2.18-2.12-3.74-2.18-3.8c-0.42-0.51-1.18-0.57-1.69-0.15
                  c-0.51,0.42-0.57,1.18-0.15,1.69c0.04,0.05,4.17,5.11,1.26,8.82c-4.19,5.34,0.28,12.38,0.47,12.68c0.23,0.35,0.61,0.55,1.01,0.55
                  c0.22,0,0.45-0.06,0.65-0.19c0.56-0.36,0.71-1.1,0.36-1.66C23.77,32.75,20.09,26.9,23.22,22.91z"/>
              </g>
            </g>
            <g>
              <g>
                <path className="st1" d="M72.29,60.55h-2.54c-0.6-14.94-12.3-27.08-27.06-28.36v-1.22c1.5-0.9,2.43-2.5,2.43-4.29
                  c0-2.77-2.25-5.02-5.02-5.02c-0.02,0-0.09,0-0.11,0c-2.77,0-5.02,2.25-5.02,5.02c0,1.79,0.93,3.39,2.43,4.29v1.22
                  C22.64,33.47,10.94,45.6,10.35,60.55H7.8c-2.19,0-3.98,1.78-3.98,3.98v1.5C3.83,68.22,5.61,70,7.8,70h64.48
                  c2.19,0,3.98-1.78,3.98-3.98v-1.5C76.26,62.33,74.48,60.55,72.29,60.55z M37.37,26.68c0-1.44,1.18-2.62,2.62-2.62
                  c0.02,0,0.09,0,0.11,0c1.44,0,2.62,1.18,2.62,2.62c0,1.08-0.66,2.04-1.67,2.43c-0.46,0.18-0.76,0.62-0.76,1.12v1.85H39.8v-1.85
                  c0-0.49-0.3-0.93-0.76-1.12C38.03,28.72,37.37,27.76,37.37,26.68z M72.29,67.6H7.8c-0.87,0-1.58-0.71-1.58-1.58v-1.5
                  c0-0.87,0.71-1.58,1.58-1.58h1.97c0.01,0,0.02,0,0.03,0h43.02c0.66,0,1.2-0.54,1.2-1.2c0-0.66-0.54-1.2-1.2-1.2H12.74
                  c0.63-14.45,12.55-26.02,27.14-26.08h0.33C54.8,34.53,66.72,46.1,67.34,60.55h-6.78c-0.66,0-1.2,0.54-1.2,1.2
                  c0,0.66,0.54,1.2,1.2,1.2l9.25,0h2.47c0.87,0,1.58,0.71,1.58,1.58v1.5h0C73.87,66.89,73.16,67.6,72.29,67.6z"/>
              </g>
            </g>
          </g>
        </svg>
    )
  }
}

export class PdfColorIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} className={this.props.className} viewBox="0 0 46 52.571">
          <g transform="translate(-32)">
            <path className="a"
                  d="M99.286,0A3.3,3.3,0,0,0,96,3.286v46a3.3,3.3,0,0,0,3.286,3.286h32.857a3.3,3.3,0,0,0,3.286-3.286V13.143L122.286,0Z"
                  transform="translate(-57.429)"/>
            <path className="b" d="M355.286,13.143h9.857L352,0V9.857A3.3,3.3,0,0,0,355.286,13.143Z"
                  transform="translate(-287.143)"/>
            <path className="c" d="M393.857,137.857,384,128h9.857Z" transform="translate(-315.857 -114.857)"/>
            <path className="d"
                  d="M71.429,258.071a1.648,1.648,0,0,1-1.643,1.643H33.643A1.648,1.648,0,0,1,32,258.071V241.643A1.648,1.648,0,0,1,33.643,240H69.786a1.648,1.648,0,0,1,1.643,1.643Z"
                  transform="translate(0 -215.357)"/>
            <g transform="translate(39.161 30.22)">
              <path className="e"
                    d="M101.744,295.227a.9.9,0,0,1,.892-.907h3.034a3.284,3.284,0,0,1,0,6.568h-2.193v1.735a.825.825,0,0,1-.841.905.885.885,0,0,1-.892-.905Zm1.733.747v3.273h2.193a1.637,1.637,0,0,0,0-3.273Z"
                    transform="translate(-101.744 -294.32)"/>
              <path className="e"
                    d="M188.731,304.562a.822.822,0,0,1-.907-.813v-7.462a.894.894,0,0,1,.907-.815h3.008c6,0,5.872,9.09.118,9.09Zm.828-7.487v5.885h2.18c3.547,0,3.7-5.885,0-5.885Z"
                    transform="translate(-178.985 -295.354)"/>
              <path className="e"
                    d="M288.854,297.166v2.088h3.35a1.016,1.016,0,0,1,.946.932.913.913,0,0,1-.946.789h-3.35v2.758a.778.778,0,0,1-.787.813.834.834,0,0,1-.932-.813v-7.462a.83.83,0,0,1,.932-.815h4.611a.819.819,0,0,1,.92.815.908.908,0,0,1-.92.894h-3.825Z"
                    transform="translate(-268.1 -295.339)"/>
            </g>
            <path className="c"
                  d="M127.214,417.643H96v1.643h31.214a1.648,1.648,0,0,0,1.643-1.643V416A1.648,1.648,0,0,1,127.214,417.643Z"
                  transform="translate(-57.429 -373.286)"/>
          </g>
        </svg>
    )
  }
}

export class PdfIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             className={this.props.className} width={this.props.width} height={this.props.height} viewBox="26 22 30 34"
             xmlSpace="preserve" fill='#283583'>
          <title id={this.props.iconTitle}>Pdf Icon</title>
          <g>
            <path d="M33.57,43.41c0-0.53-0.37-0.84-1.01-0.84c-0.26,0-0.44,0.03-0.54,0.05v1.69c0.11,0.02,0.25,0.03,0.43,0.03
              C33.15,44.34,33.57,44,33.57,43.41z"/>
            <path d="M37.56,42.59c-0.29,0-0.48,0.02-0.59,0.05v3.74c0.11,0.03,0.29,0.03,0.45,0.03c1.17,0.01,1.94-0.64,1.94-2
              C39.37,43.21,38.67,42.59,37.56,42.59z"/>
            <path d="M44.98,23H32.24c-1.86,0-3.38,1.52-3.38,3.38v12.49h-0.33c-0.75,0-1.36,0.61-1.36,1.36v8.25c0,0.75,0.61,1.36,1.36,1.36
              h0.33v1.51c0,1.86,1.51,3.38,3.38,3.38h17.38c1.86,0,3.38-1.51,3.38-3.38V30.99L44.98,23z M30.74,41.71
              c0.4-0.07,0.96-0.12,1.75-0.12c0.8,0,1.37,0.15,1.75,0.46c0.37,0.29,0.61,0.76,0.61,1.32c0,0.56-0.19,1.04-0.53,1.36
              c-0.44,0.42-1.1,0.6-1.86,0.6c-0.17,0-0.32-0.01-0.44-0.02v2.05h-1.28V41.71z M49.62,52.66H32.24c-0.72,0-1.31-0.59-1.31-1.31
              v-1.51h16.21c0.75,0,1.36-0.61,1.36-1.36v-8.25c0-0.75-0.61-1.36-1.36-1.36H30.93V26.38c0-0.72,0.59-1.31,1.31-1.31l11.97-0.01
              v4.42c0,1.29,1.05,2.34,2.34,2.34l4.33-0.01l0.05,19.54C50.93,52.07,50.35,52.66,49.62,52.66z M35.68,47.33v-5.62
              c0.48-0.08,1.09-0.12,1.75-0.12c1.09,0,1.79,0.19,2.34,0.61c0.59,0.44,0.97,1.15,0.97,2.16c0,1.1-0.4,1.85-0.95,2.32
              c-0.6,0.5-1.52,0.74-2.64,0.74C36.48,47.42,36,47.37,35.68,47.33z M44.94,44v1.05h-2.05v2.3h-1.3v-5.72h3.5v1.06h-2.2V44H44.94z"
            />
          </g>
        </svg>
    )
  }
}

export class PresentationIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 80 80" xmlSpace="preserve" className={this.props.className} width={this.props.width}
             height={this.props.height}>
          <title id={this.props.iconTitle}>Presentation icon</title>
          <g id="Warstwa_1">
            <path d="M36.48,48.91c0,3.88,3.15,7.03,7.03,7.03s7.03-3.15,7.03-7.03s-3.15-7.03-7.03-7.03S36.48,45.03,36.48,48.91z M43.52,45.39
              c1.94,0,3.52,1.58,3.52,3.52s-1.58,3.52-3.52,3.52S40,50.84,40,48.91S41.58,45.39,43.52,45.39z"/>
            <path d="M54.06,48.91c0,3.88,3.15,7.03,7.03,7.03s7.03-3.15,7.03-7.03s-3.15-7.03-7.03-7.03S54.06,45.03,54.06,48.91z M61.09,45.39
              c1.94,0,3.52,1.58,3.52,3.52s-1.58,3.52-3.52,3.52s-3.52-1.58-3.52-3.52S59.16,45.39,61.09,45.39z"/>
            <path d="M52.3,64.73c0-4.85-3.94-8.79-8.79-8.79s-8.79,3.94-8.79,8.79c0-4.85-3.94-8.79-8.79-8.79s-8.79,3.94-8.79,8.79V70H70
              v-5.27c0-4.86-4.05-8.79-8.91-8.79C56.25,55.94,52.3,59.88,52.3,64.73z M31.21,66.48H20.66v-1.76c0-2.91,2.37-5.27,5.27-5.27
              s5.27,2.37,5.27,5.27V66.48z M48.79,66.48H38.24v-1.76c0-2.91,2.37-5.27,5.27-5.27s5.27,2.37,5.27,5.27V66.48z M55.82,64.73
              c0-2.91,2.37-5.27,5.27-5.27c2.92,0,5.39,2.41,5.39,5.27v1.76H55.82V64.73z"/>
            <path d="M25.94,17.15c0-3.85-3.12-7.15-7.03-7.15c-3.92,0-7.03,3.29-7.03,7.15c0,3.85,3.12,7.15,7.03,7.15
              C22.82,24.3,25.94,21,25.94,17.15z M18.91,20.78c-1.91,0-3.52-1.66-3.52-3.63s1.61-3.63,3.52-3.63s3.52,1.66,3.52,3.63
              S20.81,20.78,18.91,20.78z"/>
            <path d="M31.21,24.3h-12.3c-2.35,0-4.57,0.91-6.26,2.56C10.94,28.53,10,30.74,10,33.09v19.34h9.85c1.22,2.1,3.49,3.52,6.09,3.52
              c3.88,0,7.03-3.15,7.03-7.03c0-3.27-2.24-6.03-5.27-6.81v-7.25h3.52v7.03H70V10H31.21V24.3z M29.45,48.91
              c0,1.94-1.58,3.52-3.52,3.52s-3.52-1.58-3.52-3.52s1.58-3.52,3.52-3.52S29.45,46.97,29.45,48.91z M24.18,31.33V42.1
              c-3.03,0.78-5.27,3.54-5.27,6.81h-5.39V33.09c0-2.86,2.47-5.27,5.39-5.27h19.34v3.52H24.18z M66.48,13.52v24.84H34.73v-3.52h7.03
              V24.3h-7.03V13.52H66.48z"/>
            <path d="M62.85,20.78h-3.52v10.55h-3.52V24.3H52.3v7.03h-3.52v-3.52h-3.52v7.03h17.58V20.78z"/>
          </g>
        </svg>
    )
  }
}


export class PrintIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };


  render() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             className={this.props.className} width={this.props.width} height={this.props.height} viewBox="23 22 34 34"
             xmlSpace="preserve" stroke='#283583'>
          <title id={this.props.iconTitle}>Print icon</title>
          <g>
            <path className="st1" d="M50.5,32.5V24h-21v8.5H25v17h3V51h1.5v3h21v-3H52v-1.5h3v-17H50.5z M30.5,25h19v7.5h-19V25z M29.5,33.5h21H54
              v8H26v-8H29.5z M29,50v-1v-0.5h-1h-2v-6h3.5V50H29z M49.5,53h-19v-2v-8.5h19V51V53z M52,48.5h-1V49v1h-0.5v-7.5H54v6H52z"/>
            <rect x="33" y="45" width="14" height="2"/>
            <rect x="33" y="48" width="14" height="2"/>
          </g>
        </svg>
    )
  }
}

export class ScheduleIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 80 80" xmlSpace="preserve" className={this.props.className} width={this.props.width}
             height={this.props.height}>
          <title id={this.props.iconTitle}>Schedule icon</title>
          <g id="Warstwa_1">
            <g>
              <path className="st1" d="M53.9,16c-0.47-2.28-2.48-4-4.9-4c-0.55,0-1,0.45-1,1s0.45,1,1,1c1.65,0,3,1.35,3,3s-1.35,3-3,3
                c-0.55,0-1,0.45-1,1s0.45,1,1,1c2.41,0,4.43-1.72,4.9-4H67v7H55h-2H41h-2H27h-2H13v-7h14h3c0.55,0,1-0.45,1-1s-0.45-1-1-1h-1.82
                c0.41-1.16,1.51-2,2.82-2c1.65,0,3,1.35,3,3s-1.35,3-3,3c-0.55,0-1,0.45-1,1s0.45,1,1,1c2.76,0,5-2.24,5-5s-2.24-5-5-5
                c-2.41,0-4.43,1.72-4.9,4H11v9v3v11v2v12v2v14h14h2h12h2h12h2h14V55v-2V41v-2V28v-3v-9H53.9z M41,27h12v12H41V27z M53,53H41V41h12
                V53z M27,41h12v12H27V41z M27,27h12v12H27V27z M13,28v-1h12v12H13V28z M13,41h12v12H13V41z M13,67V55h12v12H13z M27,67V55h12v12
                H27z M41,67V55h12v12H41z M67,67H55V55h12V67z M67,53H55V41h12V53z M55,39V27h12v1v11H55z"/>
              <path className="st1" d="M37,14c1.65,0,3,1.35,3,3s-1.35,3-3,3c-0.55,0-1,0.45-1,1s0.45,1,1,1c2.76,0,5-2.24,5-5s-2.24-5-5-5
                c-0.55,0-1,0.45-1,1S36.45,14,37,14z"/>
              <path className="st1" d="M43,14c1.65,0,3,1.35,3,3s-1.35,3-3,3c-0.55,0-1,0.45-1,1s0.45,1,1,1c2.76,0,5-2.24,5-5s-2.24-5-5-5
                c-0.55,0-1,0.45-1,1S42.45,14,43,14z"/>
            </g>
          </g>
        </svg>
    )
  }
}


export class ShareIcon extends Component {
  static defaultProps = {
    width: '27',
    height: '27'
  }

  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={this.props.width}
             height={this.props.height} viewBox="0 0 27 27" className={this.props.className}>
          <title id={this.props.iconTitle}>Share icon</title>
          <image id="share-button.svg" width="27" height="27"
                 xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAACT0lEQVRIia2WPWgUURDHf7mcIQTURmw0AQcRU+h5jVrZXAjaCVoIgRNT2eQDFUErK0U0cElEiGIUwc7aImQbK1OJFkYspohWkkYLhYgfjMzq8rj95P6wxb6dmf++ef+ZeX3tdpscbAPG/DkCDAMDwBbwEXgNrAKRiPzICpVFNgTMALPA7rw/Aj4DHWBeRL51M6ilOLaAdeBmQSLczuzXVbVVlGwKWAFGCpKEML8VVZ3KIzODhYwdF4X5L4SEyaAtz3kv0UmmNCYzMSz3YEchLN6yqg4lyWYqntF74BiwA7jg5RBixOP/lb7V0acSqkviuIisxe+qehu42sXOymJvzYu1CtEX4G2w9iHF1uKP1VwYZbAJXAvTrqp9wNmMOK060MwhmgOeA4dcSA+APcAdYEJVl4B3TnQyI06z7r0uDY+BK/7tFbAdeAicA/p9/VLBrAzXvamm4WWwbmKaKBg8xEAtRa4xTgTv1tWfAT8rkG31NxqN08C+FIOmp+4rcAo4CtwAngKDwEHgHvDIM7Q/g+yN1dld4HKJP9x00dy3nYrId/6r8UWGSOZqPvjKYBdwC9hI+ojIb1dtGlaNLPIKL4udwOHA50BKDIsf1f3QOz74yuKJqp73QXvGp3o3dOzKUPcP88DFCs3YBLKWY7Ph8f91fbszTAK/KuwuCxZvMr6TJOdXlJGGqpgVkSj2DYflIjDdgx2a/7SILCYXu01mMxgPpV0C5jceEqWRGWzro8D1EmVhdmY/mkxdErEau8EO1YrXOowNWJt71r6q3YiBP0NJjnU/fgYZAAAAAElFTkSuQmCC"/>
        </svg>
    )
  }
}

export class PlayIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x='0' y='0' viewBox="0 0 314 314" width={this.props.width} height={this.props.height} className={this.props.className} xmlSpace="preserve">
          <g>
            <path d="M293.002,78.53C249.646,3.435,153.618-22.296,78.529,21.068C3.434,64.418-22.298,160.442,21.066,235.534
              c43.35,75.095,139.375,100.83,214.465,57.47C310.627,249.639,336.371,153.62,293.002,78.53z M219.834,265.801
              c-60.067,34.692-136.894,14.106-171.576-45.973C13.568,159.761,34.161,82.935,94.23,48.26
              c60.071-34.69,136.894-14.106,171.578,45.971C300.493,154.307,279.906,231.117,219.834,265.801z M213.555,150.652l-82.214-47.949
              c-7.492-4.374-13.535-0.877-13.493,7.789l0.421,95.174c0.038,8.664,6.155,12.191,13.669,7.851l81.585-47.103
              C221.029,162.082,221.045,155.026,213.555,150.652z"/>
          </g>

        </svg>
    )
  }
}


export class CloseIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 48 48" className={this.props.className} width={this.props.width} height={this.props.height}>
          <g>
            <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
		c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
		C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
		s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"/>
          </g>
        </svg>
    )
  }
}

export class BookIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 27 27" className={this.props.className} width={this.props.width} height={this.props.height}>
          <g>
            <path d="M23.2,4.9l-1.7,0V3.2c0-0.4-0.3-0.7-0.7-0.8c-0.1,0-1.7-0.2-3.7,0.1c-2.2,0.4-3.9,1.4-5.1,2.9c-1.2-1.5-2.9-2.5-5.1-2.9
			c-2-0.4-3.6-0.1-3.7-0.1C2.8,2.5,2.5,2.8,2.5,3.2v1.7H0.8C0.4,4.9,0,5.3,0,5.7v15.1c0,0.3,0.1,0.5,0.3,0.7s0.5,0.2,0.7,0.1
			c0.1,0,6.1-2.1,10.6,0c0.2,0.1,0.5,0.1,0.7,0c4.5-2,10.5,0,10.6,0c0.1,0,0.2,0,0.3,0c0.2,0,0.3-0.1,0.5-0.1
			c0.2-0.2,0.3-0.4,0.3-0.7V5.7C24,5.3,23.6,4.9,23.2,4.9z M1.6,19.8V6.5h0.9v10.7c0,0.2,0.1,0.5,0.3,0.6C3,18,3.2,18.1,3.4,18
			c0,0,3.5-0.5,6.1,1.3C6.4,18.8,3.2,19.4,1.6,19.8z M11.2,18.6c-1.1-1-2.6-1.7-4.3-2c-0.8-0.2-1.6-0.2-2.2-0.2c-0.2,0-0.4,0-0.5,0
			V3.9h0C5.7,3.9,9.4,4,11.2,7.1V18.6z M12.8,7.1c1.8-3,5.5-3.2,7.1-3.1v12.4c-0.7,0-1.7,0-2.7,0.2c-1.7,0.3-3.2,1-4.3,2L12.8,7.1
			L12.8,7.1z M14.5,19.3c2.5-1.7,6-1.3,6.1-1.3c0.2,0,0.5,0,0.6-0.2c0.2-0.2,0.3-0.4,0.3-0.6V6.5h0.9v13.2
			C20.8,19.4,17.6,18.8,14.5,19.3z"/>
          </g>
        </svg>
    )
  }
}

export class SearchIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 27 27" className={this.props.className} width={this.props.width} height={this.props.height}>
          <g>
            <path d="M20.53,19.65,16.1,15a7.51,7.51,0,1,0-5.75,2.68,7.38,7.38,0,0,0,4.31-1.36L19.12,21a1,1,0,0,0,.71.3,1,1,0,0,0,.68-.28A1,1,0,0,0,20.53,19.65Zm-10.18-15A5.56,5.56,0,1,1,4.8,10.21,5.57,5.57,0,0,1,10.35,4.65Z"/>
          </g>
        </svg>
    )
  }
}

export class UserLinesIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="-1 -1 19 19" className={this.props.className} width={this.props.width} height={this.props.height}>
          <g>
            <path stroke='#fff' strokeWidth='1.5px' fill='none'
                  d="M0,17.326V15.16c0-2.383,3.9-4.332,8.663-4.332s8.663,1.949,8.663,4.332v2.165ZM4.331,4.332A4.331,4.331,0,1,1,8.663,8.663,4.332,4.332,0,0,1,4.331,4.332Z"
            />
          </g>
        </svg>
    )
  }
}

export class CartIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 24 24" className={this.props.className} width={this.props.width} height={this.props.height}>
          <g>
            <path d="M23.8,8.2c-0.2-0.2-0.4-0.3-0.7-0.3h-2.5l-4.9-6.6c-0.3-0.4-0.9-0.5-1.3-0.2c-0.4,0.3-0.5,0.9-0.2,1.3l4.1,5.6h-13
    l4.1-5.6c0.3-0.4,0.2-1-0.2-1.3C8.9,0.8,8.3,0.9,8,1.3L3.1,7.9H0.9C0.6,7.9,0.4,8,0.2,8.2S0,8.7,0,8.9l2.2,13.4
    c0.1,0.4,0.4,0.7,0.9,0.7h17.7c0.4,0,0.8-0.3,0.9-0.7L24,8.9C24,8.7,24,8.4,23.8,8.2z M20.1,21.3H3.9L2,9.7H22L20.1,21.3z"/>
            <path d="M12,11.8c-0.5,0-0.9,0.4-0.9,0.9v5.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-5.9C12.9,12.2,12.5,11.8,12,11.8z"/>
            <path d="M16.6,11.8c-0.5,0-0.9,0.4-0.9,0.9v5.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-5.9C17.5,12.2,17.1,11.8,16.6,11.8z
    "/>
            <path d="M7.4,11.8c-0.5,0-0.9,0.4-0.9,0.9v5.9c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-5.9C8.3,12.2,7.9,11.8,7.4,11.8z"/>
          </g>
        </svg>
    )
  }
}

export class HamburgerIcon extends Component {
  static defaultProps = {
    width: '20',
    height: '20'
  };

  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"
             className={this.props.className} width={this.props.width} height={this.props.height}>
          <g>
            <rect className="cls-1" x="1.65" y="5.46" width="20.85" height="2.67" rx="1.33" ry="1.33"/>
            <rect className="cls-1" x="1.65" y="10.79" width="20.85" height="2.67" rx="1.33" ry="1.33"/>
            <rect className="cls-1" x="1.65" y="16.13" width="20.85" height="2.67" rx="1.33" ry="1.33"/>
          </g>
        </svg>
    )
  }
}

export class SpeakerIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             className={this.props.className} width={this.props.width} height={this.props.height} viewBox="22 21 38 38"
             xmlSpace="preserve">
          <title id={this.props.iconTitle}>Speaker icon</title>
          <g id="Warstwa_1">
            <path
                d="M43.73,29.23c0-1.78-1.45-3.23-3.23-3.23s-3.23,1.45-3.23,3.23c0,1.78,1.45,3.23,3.23,3.23S43.73,31.01,43.73,29.23z M40.5,30.85c-0.89,0-1.62-0.72-1.62-1.62c0-0.89,0.72-1.62,1.62-1.62s1.62,0.72,1.62,1.62C42.12,30.12,41.39,30.85,40.5,30.85z"/>
            <path
                d="M30,51.95v1.62h21v-1.62h-3.23v-9.8H51v-4.85h-1.62v-4.54l-3.68-1.84l-0.72,1.44l2.78,1.39v3.54H46.1 c-0.39-2.74-2.75-4.85-5.6-4.85s-5.2,2.11-5.6,4.85h-1.67v-3.54l2.78-1.39l-0.72-1.44l-3.68,1.84v4.54H30v4.85h3.23v9.8H30z M40.5,34.08c1.95,0,3.58,1.39,3.96,3.23h-7.91C36.92,35.47,38.55,34.08,40.5,34.08z M31.62,38.92h17.77v1.62H31.62V38.92z M34.85,42.15h11.31v9.8H34.85V42.15z"/>
          </g>
        </svg>
    )
  }
}

export class TalkingHeadsIcon extends Component {
  static defaultProps = {
    width: '30',
    height: '30'
  };

  render() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             className={this.props.className} width={this.props.width} height={this.props.height} viewBox="0 0 80 80"
             xmlSpace="preserve">
          <title id={this.props.iconTitle}>Speaker icon</title>
          <g id="Warstwa_1">
            <path
                d="M34.48,48.91c0,3.88,3.15,7.03,7.03,7.03s7.03-3.15,7.03-7.03s-3.15-7.03-7.03-7.03S34.48,45.03,34.48,48.91z M41.52,45.39 c1.94,0,3.52,1.58,3.52,3.52s-1.58,3.52-3.52,3.52S38,50.84,38,48.91S39.58,45.39,41.52,45.39z"/>
            <path
                d="M52.06,48.91c0,3.88,3.15,7.03,7.03,7.03s7.03-3.15,7.03-7.03s-3.15-7.03-7.03-7.03S52.06,45.03,52.06,48.91z M59.09,45.39 c1.94,0,3.52,1.58,3.52,3.52s-1.58,3.52-3.52,3.52s-3.52-1.58-3.52-3.52S57.16,45.39,59.09,45.39z"/>
            <path
                d="M17.06,48.91c0,3.88,3.15,7.03,7.03,7.03s7.03-3.15,7.03-7.03s-3.15-7.03-7.03-7.03S17.06,45.03,17.06,48.91z M24.09,45.39 c1.94,0,3.52,1.58,3.52,3.52s-1.58,3.52-3.52,3.52s-3.52-1.58-3.52-3.52S22.16,45.39,24.09,45.39z"/>
            <path
                d="M50.3,64.73c0-4.85-3.94-8.79-8.79-8.79s-8.79,3.94-8.79,8.79c0-4.85-3.94-8.79-8.79-8.79c-4.85,0-8.79,3.94-8.79,8.79V70 H68v-5.27c0-4.86-4.05-8.79-8.91-8.79C54.25,55.94,50.3,59.88,50.3,64.73z M29.21,66.48H18.66v-1.76c0-2.91,2.37-5.27,5.27-5.27 c2.91,0,5.27,2.37,5.27,5.27V66.48z M46.79,66.48H36.24v-1.76c0-2.91,2.37-5.27,5.27-5.27s5.27,2.37,5.27,5.27V66.48z M53.82,64.73 c0-2.91,2.37-5.27,5.27-5.27c2.92,0,5.39,2.41,5.39,5.27v1.76H53.82V64.73z"/>
            <g>
              <path className="st2" d="M67.9,16.33H51.37c-1.71,0-3.1,1.39-3.1,3.1v1.03H40V12.2c0-1.71-1.39-3.1-3.1-3.1H12.1
                c-1.71,0-3.1,1.39-3.1,3.1v12.4c0,1.71,1.39,3.1,3.1,3.1h2.27l3.14,3.76c0.37,0.44,1.02,0.5,1.46,0.13
                c0.05-0.04,0.09-0.08,0.13-0.13l3.13-3.76h4.34v4.13c0,1.71,1.39,3.1,3.1,3.1h6.41l3.14,3.76c0.37,0.44,1.02,0.5,1.46,0.13
                c0.05-0.04,0.09-0.08,0.13-0.13l3.13-3.76h6.41c1.71,0,3.1-1.39,3.1-3.1V30.8h5.37l3.14,3.76c0.37,0.44,1.02,0.5,1.46,0.13
                c0.05-0.04,0.09-0.08,0.13-0.13l3.13-3.76h1.24c1.71,0,3.1-1.39,3.1-3.1v-8.27C71,17.72,69.61,16.33,67.9,16.33z M21.74,25.63
                c-0.31,0-0.6,0.14-0.79,0.37l-2.65,3.18l-2.65-3.18c-0.2-0.24-0.49-0.37-0.79-0.37H12.1c-0.57,0-1.03-0.46-1.03-1.03V12.2
                c0-0.57,0.46-1.03,1.03-1.03h24.8c0.57,0,1.03,0.46,1.03,1.03v12.4c0,0.57-0.46,1.03-1.03,1.03H21.74z M51.37,31.83
                c0,0.57-0.46,1.03-1.03,1.03h-6.89c-0.31,0-0.6,0.14-0.79,0.37L40,36.42l-2.65-3.18c-0.2-0.24-0.49-0.37-0.79-0.37h-6.89
                c-0.57,0-1.03-0.46-1.03-1.03V27.7h8.27c1.71,0,3.1-1.39,3.1-3.1v-2.07h10.33c0.57,0,1.03,0.46,1.03,1.03V31.83z M68.93,27.7
                c0,0.57-0.46,1.03-1.03,1.03h-1.72c-0.31,0-0.6,0.14-0.79,0.37l-2.65,3.18l-2.65-3.18c-0.2-0.24-0.49-0.37-0.79-0.37h-5.86v-5.17
                c0-1.71-1.39-3.1-3.1-3.1v-1.03c0-0.57,0.46-1.03,1.03-1.03H67.9c0.57,0,1.03,0.46,1.03,1.03V27.7z"/>
            </g>
            <g>
              <rect x="13.13" y="13.23" className="st2" width="2.07" height="2.07"/>
            </g>
            <g>
              <rect x="17.27" y="13.23" className="st2" width="2.07" height="2.07"/>
            </g>
            <g>
              <rect x="21.4" y="13.23" className="st2" width="2.07" height="2.07"/>
            </g>
            <g>
              <rect x="42.07" y="24.6" className="st2" width="2.07" height="2.07"/>
            </g>
            <g>
              <rect x="46.2" y="24.6" className="st2" width="2.07" height="2.07"/>
            </g>
            <g>
              <rect x="56.53" y="20.47" className="st2" width="2.07" height="2.07"/>
            </g>
            <g>
              <rect x="60.67" y="20.47" className="st2" width="2.07" height="2.07"/>
            </g>
            <g>
              <rect x="64.8" y="20.47" className="st2" width="2.07" height="2.07"/>
            </g>

          </g>
        </svg>
    )
  }
}