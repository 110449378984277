import {Model} from '@itsfadnis/jsonapi-client';
import {ConferenceLogos} from './ConferenceLogos';

export class ConferenceSectionExhibitors extends Model {
  static _type = 'conference_section_exhibitors';

  constructor(args={}) {
    super(args);

    this.id = args.id

    if (args.logos !== null) {
      this.logos = this.hasMany(ConferenceLogos, args.logos)
    }
  }
}