import React, {Component} from 'react';
import {CloseIcon} from '../../../assets/icons/icons'
import PopupContents from './_popup_contents';
import PropTypes from 'prop-types';


export default class Popup extends Component {
  static propTypes = {
    closePopup: PropTypes.func.isRequired,
    data: PropTypes.oneOfType([
        PropTypes.instanceOf(Object).isRequired,
        PropTypes.string.isRequired
    ]),
    popupShow: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired
  };

  _closePopup = () => {
    this.props.closePopup();
  };

  _specialClassNames = (type) => {
    switch (type) {
      case 'previousEdition':
        return 'popup_video';
      case 'promoCoupon':
        return 'marketing-alerts-popup';
      default:
        return '';
    }
  }

  render() {
    let {closePopup, type, data, popupShow} = this.props;
    let specialClassName = this._specialClassNames(type)

    return (
        <>
          {popupShow &&
            <div>
              <div className='popup-mask' onClick={this._closePopup}/>
              <div className={`popup ${specialClassName}`}>
                <button className='button popup__close' onClick={this._closePopup}>
                  <CloseIcon height='12' width='12'/>
                </button>
                <SetPopupContent type={type} data={data} close={closePopup}/>
              </div>
            </div>
          }
        </>
    )
  }
}

function SetPopupContent(props) {
  switch(props.type) {
    case 'lecturer':
      return PopupContents.LecturerPopupContent(props.data);
    case 'stream':
      return PopupContents.LiveStreamingInfoContent(props.data);
    case 'previousEdition':
      return PopupContents.PreviousEditionContent(props.data);
    case 'tooltipContent':
      return PopupContents.SimpleTextContent(props.data);
    case 'promoCoupon':
      return PopupContents.MarketingAlertPopup(props.data, props.close);
    case 'purchaseSummary':
      return PopupContents.PurchaseContent(props.data, props.close);
    case 'lecture':
      return PopupContents.LectureContent(props.data)
    default:
      return PopupContents.SubscribePopupContent(props.data);
  }
}