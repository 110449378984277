import {HttpAdapter, Model} from '@itsfadnis/jsonapi-client';
import {HeaderPodyplomieSubmenu} from './HeaderPodyplomieSubmenu';
import {base_url} from '../../../api/Api';

Model.adapter = new HttpAdapter({
  host: base_url,
  namespace: '/api/v1',
  fetch: window.fetch.bind(window)
});

export class HeaderPodyplomieMenu extends Model {
  static _type = 'menus';
  static baseUrl = '/menus/header';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.title = args.title;
    this.url = args.url;
    this.classification = args.classification;
    this.position = args.position;
    this.showInMenu = args.showInMenu;
    this.inNewWindow = args.inNewWindow;
    this.lft = args.lft;
    this.rgt = args.rgt;

    this.subMenus = this.hasMany(HeaderPodyplomieSubmenu, args.subMenus)
  }
}