import {Filter} from './filter';

export const PageHelper = {
  isSmallView: () => {
    return window.innerWidth < 992
  },

  isMobileView: () => {
    return window.innerWidth < 768
  },

  isWindowBelowMenu: (elementToCheck) => {
    return elementToCheck.offsetHeight < window.pageYOffset
  },

  getSectionFromIncluded : (included, type) => {
    return Filter.arrayByType(included, type);
  },
};