import {Component} from 'react';
import React from 'react';
import {base_url} from '../../api/Api';
import MenuBadge from './_menu_badge';
import PromoBadge from './_promo_badge';
import * as blackWeek from '../../assets/images/black_week.png';

export default class MenuElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
    }
    this.dropdown = React.createRef()
  }

  _openDropdown = (e) => {
    e.preventDefault();
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    })
    window.addEventListener('click', this._handleWindowClick)
  };

  _handleWindowClick = (e) => {
    const node = this.dropdown.current
    if (!node.contains(e.target) ) {
      this.setState({
        isDropdownOpen: false
      });
      window.removeEventListener('click', this._handleWindowClick)
    }
  };



  render() {
    const { menuItem } = this.props;

    let isDropdown = menuItem.subMenus.length > 0;
    let visibleDropdownItems = menuItem.subMenus.filter(item => item.showInMenu == true);
    let visible = this.state.isDropdownOpen ? 'dropdown_show' : '';
    let linkClassNames = `${isDropdown ? 'menu-link_dropdown' : ''} ${menuItem.classification === 'shop' ? 'menu-link_special' : ''} ${menuItem.classification === 'special_promo' ? 'menu-link_promo' : ''}`;
    return (
        <li className={`header-podyplomie__item header-podyplomie__item_relative mobile-menu__item ${menuItem.classification === 'shop' ? 'mobile-menu__item_special' : ''}`} ref={this.dropdown} >
          <a href={`${base_url}${menuItem.url === null ? '/' : menuItem.url}`}

             className={`header-podyplomie__link menu-link mobile-menu__link ${linkClassNames}`}
             onClick={isDropdown ? this._openDropdown : null}
          >
            {menuItem.title}
          </a>
          {isDropdown &&
            <div className={`dropdown ${visible}`}>
              {visibleDropdownItems && visibleDropdownItems.map((item, index) =>
                  <DropdownItem item={item} key={index}/>
              )}
            </div>
          }
          {menuItem.classification === 'special_promo' &&
            <PromoBadge image={blackWeek} className='menu-link__promo-badge'/>
          }
        </li>
    )
  }
}

function DropdownItem(props) {
  const {item} = props;

  return (
      <a href={item.url} className='dropdown__item'>
        {item.title}
        {item.badgeType.length > 0 &&
          <MenuBadge badge_type={item.badgeType[0]}/>
        }
      </a>
  )
}