import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RoomBadge extends Component {
  static propTypes = {
    roomName: PropTypes.string.isRequired,
    additionalClassNames: PropTypes.string
  };

  render() {
    const { roomName, additionalClassNames } = this.props;
    const setAdditionalClass = additionalClassNames ? additionalClassNames : '';

    return (
      <span className={`room-badge ${setAdditionalClass}`}>
          {roomName}
      </span>
    )
  }
}