export const base_url = process.env.REACT_APP_BASE_API_URL;

export default class Api {
  static getUserHeaderInfo () {
    let path = '/api/me';
    let url = (window.location.port === '' ? window.location.origin : base_url) + path;
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  static sendContactForm (id, formData) {
    let url = (window.location.port === '' ? window.location.origin : base_url) + '/api/v1/conference_infos/' + id + '/conference_contact';

    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
      },
      body: formData
    })
  }

  static sendNewsletterForm (id, formData) {
    let url = (window.location.port === '' ? window.location.origin : base_url) + '/api/v1/conference_infos/' + id + '/newsletter_contacts';

    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
      },
      body: formData
    })
  }

  static sendProducts (url) {
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin'
    })
  }

  static checkSoldFreeWorkshops(currentPlaceId) {
    let url = (window.location.port !== '3000' ? window.location.origin : base_url) + `/api/v1/conference_places/${currentPlaceId}/tickets_sold`;

    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      credentials: "include"
    })
  }
};
