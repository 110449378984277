import {Model} from '@itsfadnis/jsonapi-client';

export class Slider extends Model {
  static _type = 'conference_sliders'

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.image = args.image;
    this.mediumImage = args.mediumImage;
    this.thumbImage = args.thumbImage;

  }
}