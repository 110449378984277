import React, {Component} from 'react';
import logo from '../../assets/images/logo_podyplomie.svg'
import {
  CartIcon, CloseIcon,
  HamburgerIcon,
  SearchIcon,
  UserLinesIcon
} from '../../assets/icons/icons'
import Popup from '../congress_single/popups/popup';
import PopupData from '../../assets/contentData/subscribe_data'
import MenuElement from './_menu_elements';
import {HeaderPodyplomieMenu} from '../../models/congress_single/entity/HeaderPodyplomieMenu';

export default class HeaderPodyplomie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: this.props.isLoggedIn,
      numberOfItemsInCart: this.props.productsInCart,
      menuOpen: false,
      searchOpen: false,
      popupOpen: false,
      menuElements: {}
    };

    this.header = this.props.ref
  }

  _handleMenuOpen = () => {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  };

  _handleSearchMobileOpen = () => {
    this.setState({
      searchOpen: !this.state.searchOpen
    })
  };

  _handlePopupBehavior = () => {
    this.setState({popupOpen: !this.state.popupOpen})
  };

  componentDidMount() {
    HeaderPodyplomieMenu.fetch('/menus/header?include=subMenus')
        .then(resp => {
          this.setState({
            menuElements: resp
          })
        })
  }

  render() {
    let iconSize = '20';
    let popupData = PopupData;

    const {menuElements} = this.state;

    return (
        <header className={`header-podyplomie ${this.state.menuOpen && 'header-podyplomie_fixed'}`} ref={this.header}>
          <div className="grid__container">
            <div className='header-podyplomie__container'>
              <div className='header-podyplomie__separator d_none d_lg_block'/>
              <div className='header-podyplomie__item d_flex d_lg_none'>
                <button className='header-podyplomie__link menu-link menu-link_logo button'
                        onClick={this._handleMenuOpen}>
                  <SetMobileMenuIcon isOpen={this.state.menuOpen}/>
                </button>
              </div>

              <div className='header-podyplomie__item'>
                <a href='/' className='header-podyplomie__link menu-link menu-link_logo'>
                  <img src={logo} alt="Logo Podyplomie.pl" className='header-podyplomie__logo'/>
                </a>
              </div>

              <div className='header-podyplomie__item header-podyplomie__item header-podyplomie__item_grow'>
                <form action='/search'
                      className={`header-podyplomie__form ${this.state.searchOpen ? 'header-podyplomie__form_show' : ''}`}
                      method='get' role='search'>
                  <div className='input-wrapper'>
                    <input id="navbarSearch"
                           className="input header-podyplomie__search-input"
                           type='text'
                           name='q'
                           placeholder='Szukaj'/>
                    <button type='submit' className='button button-search'>
                      <SearchIcon className='icon_green' width='20' height='20'/>
                    </button>
                  </div>

                </form>
              </div>

              <div className='header-podyplomie__item d_none d_lg_block'>
                <button className='button header-podyplomie__link menu-link_subscribe'
                        onClick={this._handlePopupBehavior}>
                  Zamów prenumeratę
                </button>
              </div>

              <div className='header-podyplomie__separator d_none d_lg_block'/>

              <div className={`mobile-menu ${this.state.menuOpen ? 'mobile-menu_show' : ''}`}>
                <ul className='header-podyplomie__list mobile-menu__list'>
                  {menuElements.length > 0 && menuElements.map((menuItem, index) =>
                      <MenuElement menuItem={menuItem} key={index}/>
                  )}
                </ul>

                <div className='header-podyplomie__separator_vertical'/>

                <div className='header-podyplomie__item d_flex d_lg_none mobile-menu__item mobile-menu__item_mt'>
                  <button className='header-podyplomie__link button menu-link_mobile menu-link_subscribe button_mobile-menu'
                          onClick={this._handlePopupBehavior}>
                    Zamów prenumeratę
                  </button>

                </div>


                <div className='header-podyplomie__item d_flex d_lg_none header-podyplomie__item_mobile-mb mobile-menu__item mobile-menu__item_mt'>
                  <SetLoginButton isLoggedIn={this.state.loggedIn} iconSize={iconSize}/>
                </div>
              </div>

              <div className='header-podyplomie__separator_vertical'/>

              <div className='header-podyplomie__item d_none d_lg_flex'>
                <SetLoginButton isLoggedIn={this.state.loggedIn} iconSize={iconSize}/>
              </div>
              <div className='header-podyplomie__item d_flex d_lg_none'>
                <button className='header-podyplomie__link menu-link menu-link_logo button'
                        onClick={this._handleSearchMobileOpen}>
                  <SetSearchShowButton searchOpen={this.state.searchOpen} iconSize={iconSize}/>
                </button>
              </div>
              <div className='header-podyplomie__item'>
                <a href="/order" className='header-podyplomie__link menu-link'>
                  <CartIcon width={iconSize} height={iconSize} className='icon_white'/>
                  <SetItemsInBadge numberOfItemsInCart={this.state.numberOfItemsInCart}/>
                </a>
              </div>
              <div className='header-podyplomie__item d_flex d_lg_none'>
                <a href={this.state.isLoggedIn ? '/auth/account/user' : '/auth/login'} className='header-podyplomie__link menu-link'>
                  <SetUserIcon isLoggedIn={this.state.loggedIn} iconSize={iconSize}/>
                </a>
              </div>

              <div className='header-podyplomie__separator d_none d_lg_block'/>
            </div>
          </div>
          {this.state.popupOpen &&
              <Popup data={popupData} type='subscribe' popupShow={this.state.popupOpen}
                 closePopup={this._handlePopupBehavior}/>
          }
        </header>
    )
  }
}

function SetSearchShowButton(props) {
  if (props.searchOpen) {
    return (
        <CloseIcon className='icon_white' width={props.iconSize} height={props.iconSize}/>
    )
  } else {
    return (
        <SearchIcon className='icon_white' width={props.iconSize} height={props.iconSize}/>
    )
  }
}

function SetLoginButton(props) {
  if (props.isLoggedIn) {
    return (
        <a href="/auth/account/user" className='header-podyplomie__link button button-success menu-link_mobile button_mobile-menu'>
          <span className='menu-link__login'>
            Konto
          </span>
          {!window.isMobileView &&
            <SetUserIcon height={props.iconSize}/>
          }
        </a>
    )
  } else {
    return (
        <a href="/auth/login" className='header-podyplomie__link button button-success menu-link_mobile button_mobile-menu'>
          <span className='menu-link__login'>
            Logowanie
          </span>
          {!window.isMobileView &&
            <SetUserIcon height={props.iconSize}/>
          }

        </a>
    )
  }
}

function SetUserIcon(props) {
  if (props.isLoggedIn || window.isMobileView) {
    return (
      <UserLinesIcon width='20' height={props.iconSize} className='icon_white icon_margined'/>
    )
  }
  return null;
}

function SetItemsInBadge(props) {
  let numberOfItems = props.numberOfItemsInCart;

  return (
      <span className='menu-link__badge'>
        {numberOfItems}
      </span>
  )
}

function SetMobileMenuIcon(props) {
  return props.isOpen
    ? <CloseIcon className='icon_white' width='20' height='20'/>
    : <HamburgerIcon className='icon_white' width='20' height='20'/>;
}
