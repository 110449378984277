import React, {Component} from 'react';
import moment from 'moment';
import HeaderSlider from './header_slider';
import HeaderTitleCard from './header_title_card';
import HeaderSticker from './header_sticker';
import HeaderBadge from './header_badge';
import HTMLContentHelper from '../../helpers/html_content_helper';


export default class Header extends Component {
  render() {
    const {conference, currentCityId, isCongressFuture, currentPlace} = this.props;

    const titleCardData = {
      desktopFontSize: conference.specializationFontSize,
      mobileFontSize: conference.specializationMobileFontSize,
      specialization: conference.specialization,
      title: conference.title,
      claim: conference.claim,
      startDate: moment(currentPlace.starting),
      endDate: moment(currentPlace.finishing),
      currentPlace: currentPlace,
      isCongressFuture: isCongressFuture
    };

    const badgeData = {
      educationalPoints: currentPlace.educationalPoints
    };

    const sliderData = {
      sliders: conference.sliders
    };

    const marketingAlertHeader = HTMLContentHelper.hasHtmlStringInnerText(currentPlace.marketingAlertHeader) ? currentPlace.marketingAlertHeader : null ;

    return(
      <div className='header' id='header'>
        <HeaderSlider
          sliderData={sliderData}
          currentPlace={currentPlace}
          conference={conference}
        />

        {marketingAlertHeader &&
          <HeaderSticker marketingAlertHeader={marketingAlertHeader}/>
        }

        <HeaderTitleCard currentCityId={currentCityId}
                         titleCardData={titleCardData}/>
        {badgeData.educationalPoints > 0 && badgeData.educationalPoints !== null &&
          <HeaderBadge badgeData={badgeData}/>
        }
      </div>
    )
  }
}