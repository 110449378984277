import React, { Component } from 'react';

export default class Workshop extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  _handleChange = () => {
    this.props.allowMultipleWorkshops
      ? this.props.setChecked(this.props.workshop, this.input.current.checked)
      : this.props.setChecked(this.props.workshop)
  };

  render() {
    const {
      workshop,
      checkedProducts,
      sentProducts,
      inputType,
      allowMultipleWorkshops,
      allProductsSent
    } = this.props;

    const parsedPrice = Number(workshop.baseDiscountPrice);
    const checked = checkedProducts.includes(workshop);
    const workshopClasses = `
    ${checked ? 'workshop-item_checked' : ''} 
    ${(allowMultipleWorkshops && sentProducts.includes(workshop)) || allProductsSent ? 'workshop-item_sent' : ''}`;

    return (
      <li className={`ticket-purchase__input workshop-item ${workshopClasses}`}>
        <div className='grid__row'>
          <div className='grid__col_8 grid__col_md_9'>
            <input
              type={inputType}
              name='workshop-item'
              id={`workshop-${workshop.productId}`}
              className={`purchase-summary__${inputType}`}
              ref={this.input}
              onChange={this._handleChange}
              checked={checked}
            />

            <label htmlFor={`workshop-${workshop.productId}`} className='purchase-summary__label'>
              {workshop.name}
            </label>
          </div>

          <div className="grid__col_4 grid__col_md_3">
            <p className='workshop-item__price'>
              {parsedPrice} zł
            </p>
          </div>
        </div>
      </li>
    )
  }
}