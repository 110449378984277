import React, {Component} from 'react';
import PropTypes from 'prop-types';
import archiveData from '../../../assets/contentData/archive_data';
import ArchivedYear from './archived_year';

export default class ArchivedCongresses extends Component {
  static propTypes = {
    newCongressPages: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    this.oldCongressPages = archiveData;

    this.state = {
      showArchive: false
    }
  }

  _toggleArchive = () => {
    this.setState({
      showArchive: !this.state.showArchive
    })
  };

  _mergeArchiveLinksWithNewData = (newCongresses, oldCongresses) => {
    let resultObject = oldCongresses;

    //match new congresses to old
    Object.keys(resultObject).forEach(year => {
      if (newCongresses.hasOwnProperty(year)) {
        resultObject[year] = Array.of(...oldCongresses[year], ...newCongresses[year]);
      } else {
        resultObject[year] = oldCongresses[year]
      }
    });

    //create new year if old congresses don't have one
    Object.keys(newCongresses).forEach(year => {
      if (!resultObject.hasOwnProperty(year)) {
        resultObject[year] = newCongresses[year]
      }
    });

    return resultObject
  };


  render() {
    const { newCongressPages } = this.props;

    const finalArchiveObject = this._mergeArchiveLinksWithNewData(newCongressPages, this.oldCongressPages);
    const keysOfFinalArchiveObject = Object.keys(finalArchiveObject).reverse()

    return (
          <div className="congress-list-archive">
            <button className='button congress-list-archive__button' onClick={this._toggleArchive}>
              Archiwum
              <span
                className={`arrow arrow-md arrow-md_black ${this.state.showArchive ? 'arrow-md_up' : 'arrow-md_down'}`}/>
            </button>
            <div className={`grid__row congress-list-archive__row ${this.state.showArchive ? 'congress-list-archive__row_show' : ''}`}>
              {keysOfFinalArchiveObject.length > 0 && keysOfFinalArchiveObject.map((year, index) =>
                <ArchivedYear year={year} congressesInYear={finalArchiveObject[year]} key={index}/>
              )}
            </div>
          </div>
    )
  }
}



