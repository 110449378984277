import React, {Component} from 'react';
import {CoffeeIcon, LectureIcon, MealIcon, SpeakerIcon, TalkingHeadsIcon} from "../../../assets/icons/icons";
import {CongressProgramWorkshopsEvents} from './congress_program_workshops';
import {CongressProgramSessions} from './congress_program_sessions';
import RoomBadge from '../../shared/_room_badge'
import moment from 'moment';

export default class CongressProgramExtended extends Component {
  render() {
    const properIcon = (type) => {
      switch (type) {
        case 'lecture':
          return <SpeakerIcon className='congress-lecture__icon' width='40' height='40'/>;
        case 'discussion':
          return <TalkingHeadsIcon className='congress-lecture__icon' width='40' height='40'/>;
        case 'coffee break':
          return <CoffeeIcon className='congress-lecture__icon' width='40' height='40'/>;
        case 'lunch break':
          return <MealIcon className='congress-lecture__icon' width='40' height='40'/>;
        default:
          return <LectureIcon className='congress-lecture__icon' width='40' height='40'/>;
      }
    };

    const {sessionsByDays, workshopsByDays, sessionDates, workshopDates, sessionsRoomNames, sessionsByRoomNames} = this.props

    return (
        <div className='congress-program-extended'>
          <div className="grid__container">
            <div className='grid__row'>

              <SessionsByDays
                  sessionDates={sessionDates}
                  sessionsByDays={sessionsByDays}
                  sessionsByRoomNames={sessionsByRoomNames}
                  sessionsRoomNames={sessionsRoomNames}
                  properIcon={properIcon}
              />

            </div>
            <div className='grid__row'>
              {workshopDates.length > 0 &&
              <>
                <p className='congress-program-extended__promo-info'>
                  WARSZTATY
                </p>
                {workshopDates.map((date, i) =>
                  <CongressWorkshopDay
                    key={i}
                    index={i}
                    workshops={workshopsByDays}
                    date={date}
                    properIcon={properIcon}
                  />
                )}
              </>
              }
            </div>
          </div>
        </div>
    )
  }
}

function SessionsByDays(props) {
  const {sessionDates, sessionsRoomNames, properIcon, sessionsByDays, sessionsByRoomNames} = props;
  const roomNamesAny = sessionsRoomNames.length > 0;

  return (
      <>
        {sessionsByDays && sessionDates.map((day, index) =>
            <div className={`grid__col_12 ${sessionDates.length > 1 && sessionsRoomNames.length < 2 ?  'grid__col_md_6' : ''}`} key={index} >
              <h3 className='congress-program-extended__title'>
                Dzień {index+1} - {moment(day).format('DD.MM.YYYY')}
              </h3>
              <div className='grid__row'>

                {(roomNamesAny && !window.isMobileView) && sessionsRoomNames.map((roomName, index) =>
                    <div className={`grid__col_12 ${sessionsRoomNames.length > 1 ? 'grid__col_md_6' : ''}`} key={index}>
                      <SessionsByRoomNames
                          roomName={roomName}
                          sessions={sessionsByRoomNames[roomName]}
                          properIcon={properIcon}
                          day={day}
                      />
                    </div>
                )}

                {(!roomNamesAny || window.isMobileView) && sessionsByDays[day].map((session, index) =>
                    <div className='grid__col_12' key={index}>
                      <CongressProgramSessions
                          session={session}
                          index={index}
                          properIcon={properIcon}/>
                    </div>
                )}

              </div>
            </div>
        )}
      </>
  )
}

function SessionsByRoomNames(props) {
  const {properIcon, sessions, roomName, day} = props;
  return (
      <>
        <RoomBadge roomName={roomName} additionalClassNames='congress-program-extended__room-badge'/>
        {sessions.length > 0 && sessions.map((session, index) => {
          if (session.date === day) {
            return (
                <CongressProgramSessions
                    session={session}
                    date={session.date}
                    index={index}
                    key={index}
                    properIcon={properIcon}
                    roomName={roomName}
                />
            )
          }
          return null
        })}
      </>
  )
}

function CongressWorkshopDay(props) {
  const {index, workshops, date, properIcon} = props;
  return (
      <div className='grid__col_12'>
        <p className='congress-program-extended__title congress-program-extended__title_inline'>
          {moment(date).format('DD.MM.YYYY')}
        </p>
        {index === 0 &&
        <p className='congress-program-extended__promo-info'>
          {workshops[date][0].description}
        </p>
        }
        {workshops[date].map((workshop) => {
          return workshop.events.length > 0 && workshop.events.map((event, index) =>
              <CongressProgramWorkshopsEvents event={event} key={index} properIcon={properIcon} index={index}/>
          )
        })}
      </div>
  )
}