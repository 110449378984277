import 'es6-promise';
import 'isomorphic-fetch';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/styles/main.scss'
import ErrorView from './components/shared/_error_view';

try {
  ReactDOM.render(<App />, document.getElementById('root'));
}
catch (e) {
  if (window.navigator.userAgent.indexOf('msie') > 0) {
    const container = document.createElement('div');
    container.classList.add('error-handler');
    container.innerHTML = `
      <h1 class='error-handler__title'>Coś poszło nie tak</h1> 
      <p class='error-handler__text'>
        Spróbuj uruchomić stronę w innej przeglądarce (np. Google Chrome, Mozilla Firefox lub Microsoft Edge).
      </p>
      <p class='error-handler__text'>
        Jeżeli to nie przynosi efektu, skontaktuj się z nami drogą mailową na adres <a href='mailto:kontakt@podyplomie.pl'>kontakt@podyplomie.pl</a>
      </p>
    `
    document.getElementById('root').appendChild(container);
  } else {
    ReactDOM.render(<ErrorView/>, document.getElementById('root'));
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
