import React, {Component} from 'react';
import HeaderPodyplomie from '../shared/_header_podyplomie';
import Hero from './hero';
import Main from './main';
import {Conference} from '../../models/congress_single/entity/Conference'
import Api from '../../api/Api';
import FooterPodyplomie from '../shared/_footer_podyplomie';
import SeoTags from '../shared/_seo_tags';
import {Sort} from '../../helpers/sort';

export default class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCityId: null,
      isLoggedIn: false,
      productsInCart: 0
    }
  }

  componentDidMount() {
    Conference.fetch(`${this.props.match.params.slug}?include=**`)
        .then(conference => {
          this.setState({conference: conference})
        })
        .then(() => {
          this.setState({
            currentCityId: this._getMatchingPlacefromUrl(),
            isLoading: false
          })
        });

    this._checkUserAttributes()
  }

  _getMatchingPlacefromUrl = () => {
    let properCity = this.props.match.params.city
      ? this.state.conference.conferencePlaces.find(place => {return  place.city.toUpperCase() === this.props.match.params.city.toUpperCase()})
      : undefined

    return this.props.match.params.city
      ? properCity !== undefined
        ? properCity.id
        : Sort.by_position(this.state.conference.conferencePlaces)[0].id
      : Sort.by_position(this.state.conference.conferencePlaces)[0].id
  }

  _checkUserAttributes = () => {
    Api
        .getUserHeaderInfo()
        .then(response => response.json())
        .then( data =>
            this.setState({
              isLoggedIn: data.api_token !== null,
              productsInCart: data.products_in_cart
            }))
        .catch(error => console.error(error));
  };

  changeCurrentCityId = (id) => {
    this.setState({currentCityId: id})
  };

  render() {

    if(this.state.currentCityId) {
      const conference = this.state.conference
      const currentPlace = conference.conferencePlaces.find(place => place.id === this.state.currentCityId);

      return (
          <div>
            <SeoTags metaData={conference.metaData} type='single'/>
            <HeaderPodyplomie isLoggedIn={this.state.isLoggedIn} productsInCart={this.state.productsInCart}/>
            <Hero
                currentCityId={this.state.currentCityId}
                changeCurrentCityId={this.changeCurrentCityId}
                conference={conference}
                isCongressFuture={currentPlace.isCongressFuture}
                currentPlace={currentPlace}
            />
            <Main
                conference={conference}
                currentCityId={this.state.currentCityId}
                changeCurrentCityId={this.changeCurrentCityId}
                checkUserAttributes={this._checkUserAttributes}
                isCongressFuture={currentPlace.isCongressFuture}
                currentPlace={currentPlace}
            />
            <FooterPodyplomie/>
          </div>
      )
    } else {
      return (
          <div className="bouncing-loader">
            <div className="bouncing-loader__dot"/>
            <div className="bouncing-loader__dot"/>
            <div className="bouncing-loader__dot"/>
          </div>
      )
    }
  }
}